import { Component, OnInit, Input } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { CartItems, ItemsEntity } from 'src/app/models/cart.model';
import { CartService } from 'src/app/services/cart.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-item-detail',
  templateUrl: './item-detail.component.html',
  styleUrls: ['./item-detail.component.scss'],
})
export class ItemDetailComponent implements OnInit {
  @Input() item: any;
  @Input() itemType: string;
  constructor(
    private cartService: CartService,
    private userService: UserService,
    public loadingCtrl: LoadingController,
    public router: Router,
    private toast: ToastService
  ) { }

  ngOnInit() {

  }
  getName(item: any) {
    if (item.skuPDPDisplayTitle) {
      return item.skuPDPDisplayTitle;
    }else{
      return item.itemOrProductDescription;
    }
  }
  decreaseQty(item: any){
    if (item.quantity > 0) {
      item.quantity--;
    }
  }
  increaseQty(item: any){
    item.quantity++;
  }

  async addItemToCart(item: any){
    const loading = await this.loadingCtrl.create();
    await loading.present();
    const payload: CartItems = {
      accountId: this.userService.session.accountId.toString(),
      addItemCount: 1,
      items: [{
        catalogRefId: item.itemNumber,
        productId: item.productId,
        quantity: item.quantity,
        uom: item.unitOfMeasure,
        catalogRefIdChanged: false,
      }],
    }
    this.cartService.addItemsToCartFromOrder(payload).subscribe((res: any) => {
      loading.dismiss();
      const itemsAdded = res.data.result.addedToCartItems;
      const resultMessage = itemsAdded && itemsAdded.length
        ? `The following item(s) have been added to cart, ${itemsAdded.join(', ')}`
        : `Nothing was added to your cart`;
      this.toast.showMessage(resultMessage, 5000)
    })
  }

  
  viewItemDetail(item: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        productId: item.productId,
        itemNumber: item.itemNumber,
        accountId: this.userService.session.accountId.toString(),
      }
    };
    this.router.navigate(['/proplus/product-detail'], navigationExtras);
  }
}
