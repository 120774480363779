import {
	ProplusImageRenditionTypes,
	ProplusImageRenditionTypesOptions,
} from 'src/app/enums/proplus-image-rendition-types';
import { environment } from '../../environments/environment';

/**
* From webapp - Class that should be used whenever image references are used.
*/
export class Image {
	src: string;
	altText: string;
	appendProPlusUrl: boolean;

	constructor(
			src: string,
			altText: string,
			appendProPlusUrl: boolean,
			imageServiceUrl = `${environment.cacheServiceRoot}${environment.imageServiceUri}`
	) {
			this.appendProPlusUrl = appendProPlusUrl;

			/**
			 * If we are appending the pro plus url, also strip off any rendition keys that may be on the images
			 * src.  This will allow us to dynamically set the rendtion.
			 */
			if (this.appendProPlusUrl) {
					const renditionKeys = Object.keys(ProplusImageRenditionTypes).map(
							(k) => k as ProplusImageRenditionTypesOptions
					);
					renditionKeys.forEach((curKey) => {
							if (src.includes(`_${ProplusImageRenditionTypes[curKey]}`)) {
									src = src.replace(
											`_${ProplusImageRenditionTypes[curKey]}`,
											''
									);
							}
					});
			} else if (src && !environment.local && !src.includes('_cmsinternal')) {
					src = src.replace(
							`${environment.hippoRootProto}://${environment.hippoRootUrl}:${environment.hippoRootPort}`,
							''
					);
			}

			// Reroute the src to our caching service based on the current environment variables.
			if (this.appendProPlusUrl) {
					this.src = `${imageServiceUrl ? imageServiceUrl : ''}${src}`;
			} else if (!environment.local && !src.includes('_cmsinternal')) {
					this.src = `${imageServiceUrl ? imageServiceUrl : ''}${src}`;
			} else {
					this.src = src;
			}
			this.src = this.src.replace(/([^:])\/\//, '$1/');

			this.altText = altText;
	}
	/**
 * Adds a new string to a specified position in an existing string.
 * @param string the string to be modified
 * @param position position to insert into the new string
 * @param stringToAdd the string to be inserted.
 */
	 spliceString(
		string: string,
		position: number,
		stringToAdd: string
	) {
		return [
				string.slice(0, position),
				stringToAdd,
				string.slice(position),
		].join('');
	}

	/**
	 * Returns the src to the hero rendition.
	 */
	heroSrc(): string {
			const extensionIndex = this.src.lastIndexOf('.');
			return this.spliceString(
					this.src,
					extensionIndex,
					`_${ProplusImageRenditionTypes.hero}`
			);
	}

	/**
	 * Returns the src to the small rendition.
	 */
	smallSrc(): string {
			const extensionIndex = this.src.lastIndexOf('.');
			return this.spliceString(
					this.src,
					extensionIndex,
					`_${ProplusImageRenditionTypes.small}`
			);
	}

	/**
	 * Returns the src to the swatch rendition.
	 */
	swatchSrc(): string {
			const extensionIndex = this.src.lastIndexOf('.');
			return this.spliceString(
					this.src,
					extensionIndex,
					`_${ProplusImageRenditionTypes.swatch}`
			);
	}

	/**
	 * Returns the src to the swatch rendition.
	 */
	thumbSrc(): string {
			const extensionIndex = this.src.lastIndexOf('.');
			return this.spliceString(
					this.src,
					extensionIndex,
					`_${ProplusImageRenditionTypes.thumb}`
			);
	}
}
