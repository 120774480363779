import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocationsService } from 'src/app/services/locations.service';
import { Location } from 'src/app/classes/location';
import { Subscription, Observable } from 'rxjs';
import { ActionSheetController, Platform } from '@ionic/angular';
import { Geolocation } from '@capacitor/geolocation';
@Component({
	selector: 'app-store-locator',
	templateUrl: './store-locator.component.html',
	styleUrls: ['./store-locator.component.scss'],
})
export class StoreLocatorComponent implements OnInit {
	@Input() source?: string;
	@Output() selectedAddress = new EventEmitter<any>();

	resultsList!: Location[];
	locationsSub!: Subscription;
	platformType: string;
	labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';

	radioArray = [{}];

	loading$!: Observable<boolean>;
	isCart: boolean = false;

	constructor(
		private readonly locationsService: LocationsService,
		public actionSheetCtrl: ActionSheetController,
		private readonly platform: Platform
	) {
		this.platformType = this.platform.is('mobileweb')
			? 'web'
			: this.platform.is('ios')
				? 'ios'
				: 'android';
	}

	ngOnInit() {
		this.locationsSub = this.locationsService.locations$.subscribe(
			(newLocations) => {
				this.resultsList = newLocations;
				// console.log('resultsList', this.resultsList);
				if (this.source === 'cart') {
					this.isCart = true;
				}
			}
		);
		this.loading$ = this.locationsService.loading$;
	}
	selectLocation(resLocation: any) {
		// console.log(resLocation);
		this.selectedAddress.emit(resLocation);
	}

	async openContact(result: any) {
		const mode = 'ios'; // this.config.get('mode');
		const actionSheet = await this.actionSheetCtrl.create({
			header: 'Contact ' + result.storeName,
			buttons: [
				{
					text: `Call  ${result.phoneNumber}`,
					icon: mode !== 'ios' ? 'call' : null,
					cssClass: 'open-contact',
					handler: () => {
						window.open('tel:' + result.phoneNumber);
					},
				},
				{
					cssClass: 'open-contact',
					text: 'Cancel',
					role: 'cancel',
				},
			],
		});

		await actionSheet.present();
	}

	openMap(result: any) {
		if (result.latitude) {
			let destination = result.latitude + ',' + result.longitude;
			this.platform.ready().then(() => {
				Geolocation.getCurrentPosition().then((position) => {
					// ios
					if (this.platform.is('ios')) {
						window.open(
							'maps://?q=' +
							result.storeName +
							'&saddr=' +
							position.coords.latitude +
							',' +
							position.coords.longitude +
							'&daddr=' +
							destination,
							'_system'
						);
					}
					// android
					if (this.platform.is('android')) {
						window.open(
							'geo:0,0?q=' +
							destination +
							'(' +
							result.storeName +
							')',
							'_system'
						);
					}
				});
			});
		}
	}
}
