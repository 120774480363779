import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { SwiperModule } from 'swiper/angular';

import { ItemDetailComponent } from './item-detail/item-detail.component';
import { OrderItemSummaryComponent } from './order-item-summary/order-item-summary.component';
import { AttributeVariationsComponent } from './attribute-variations/attribute-variations.component';
import { AddToTemplateComponent } from './add-to-template/add-to-template.component';
import { ProductCarouselComponent } from './product-carousel/product-carousel.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { AccordionComponent } from './accordion/accordion.component';
import { AccountSelectorComponent } from './account-selector/account-selector.component';
import { OrderItemComponent } from './order-item/order-item.component';
import { SuggestedItemsComponent } from './suggested-items/suggested-items.component';
import { AccountSelectorModal } from './account-selector/modal/account-selector-modal';
import { SharedModule } from '../shared/shared.module';
import { ProductFilterComponent } from './product-filter/product-filter.component';
import { GooglemapsComponent } from './googlemaps/googlemaps.component';
import { StoreLocatorComponent } from './store-locator/store-locator.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { FindAStoreComponent } from './find-a-store/find-a-store.component';
import { SearchSelectorComponent } from './search-selector/search-selector.component';
import { NumericInputComponent } from './numeric-input/numeric-input.component';
import { CartSummaryComponent } from './cart-summary/cart-summary.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { GlobalStyleDemoComponent } from './global-style-demo/global-style-demo.component';
import { ProductDetailButtonComponent } from './product-detail-button/product-detail-button.component';
import { PopoverPage } from './account-selector/notification-popover/notification-popover';
import { CartMapPickupComponent } from '../pages/cart-review/modal/cart-map-pickup/cart-map-pickup.component';
import { AddProductModalComponent } from './add-product-modal/add-product-modal.component';
import { ModalBaseComponent } from './modal-base/modal-base.component';
import { ProductQuickAddComponent } from './product-quick-add/product-quick-add.component';
import { ConfirmAvailableProductsComponent } from './confirm-available-products/confirm-available-products.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    SwiperModule,
    RouterModule,
    SharedModule,
    GoogleMapsModule,
  ],
  exports: [
    ItemDetailComponent,
    OrderItemSummaryComponent,
    AttributeVariationsComponent,
    AddToTemplateComponent,
    ProductCarouselComponent,
    BackButtonComponent,
    AccordionComponent,
    AccountSelectorComponent,
    OrderItemComponent,
    SuggestedItemsComponent,
    AccountSelectorModal,
    ProductFilterComponent,
    FindAStoreComponent,
    GooglemapsComponent,
    StoreLocatorComponent,
    SearchSelectorComponent,
    NumericInputComponent,
    CartSummaryComponent,
    ProductCardComponent,
    SearchBoxComponent,
		GlobalStyleDemoComponent,
		ProductDetailButtonComponent,
    ProductDetailButtonComponent,
    AddProductModalComponent,
    ModalBaseComponent,
    ProductQuickAddComponent,
    CartMapPickupComponent,
    ConfirmAvailableProductsComponent,
  ],
  declarations: [
    ItemDetailComponent,
    OrderItemSummaryComponent,
    AttributeVariationsComponent,
    AddToTemplateComponent,
    ProductCarouselComponent,
    BackButtonComponent,
    AccordionComponent,
    AccountSelectorComponent,
    OrderItemComponent,
    SuggestedItemsComponent,
    AccountSelectorModal,
    ProductFilterComponent,
    FindAStoreComponent,
    GooglemapsComponent,
    StoreLocatorComponent,
    SearchSelectorComponent,
    NumericInputComponent,
    CartSummaryComponent,
    ProductCardComponent,
    SearchBoxComponent,
		GlobalStyleDemoComponent,
		ProductDetailButtonComponent,
    CartMapPickupComponent,
    PopoverPage,
    AddProductModalComponent,
    ModalBaseComponent,
    ProductQuickAddComponent,
    ProductDetailButtonComponent,
    ConfirmAvailableProductsComponent,
  ],
})
export class ComponentModule {}
