import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Share } from '@capacitor/share';

import SwiperCore, { Pagination, Navigation } from 'swiper';

SwiperCore.use([Pagination, Navigation]);

@Component({
  selector: 'app-product-carousel',
  templateUrl: './product-carousel.component.html',
  styleUrls: ['./product-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductCarouselComponent implements OnInit {
  @Input() item: any;
  @Input() source: string;
  @Input() brand: string
  constructor() { }

  ngOnInit() {
	}
  onSlideChange(event) {
  }

  async shareImage(){
    await Share.share({
      title: 'Beacon Roofing Supply',
      text: 'Beacon - Order Delivery Photo',
      url: this.item[0].thumbnailUrl,
    });
  }

}
