import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnalyticsService } from '../../services/analytics.service';
import { LocationsService } from '../../services/locations.service';

@Component({
  selector: 'app-find-a-store',
  templateUrl: './find-a-store.component.html',
  styleUrls: ['./find-a-store.component.scss'],
})
export class FindAStoreComponent implements OnInit {
  @Input() zip?: string;
  @Input() source?: string;
  @Output() selectedAddressToCart = new EventEmitter<any>();

  constructor(private location: LocationsService, public analyticsService: AnalyticsService) {}
  zipCode = '';
  selectedMiles = 25;

  miles = [
    { value: 25, label: '25 Miles' },
    { value: 50, label: '50 Miles' },
    { value: 100, label: '100 Miles' },
  ];
  ngOnInit() {
    this.location.range = 25;
    if (this.zip) {
      // Review. This should be done on focus so we can get the current user location
      this.zipCode = this.zip;
      // this.location.getLocations(this.zip);
    }
  }
  onLocationSearch(event: any) {
    this.trackEvents('store_locator_search');
    this.location.getLocations(event.target.value);
  }
  onDistanceChange(event: any) {
    this.location.range = event.target.value;
    this.location.getLocations();
  }
  emitAddress(address: any) {
    this.selectedAddressToCart.emit(address);
  }
  trackEvents(eventName: string, source?: any){
    this.analyticsService.logEvent(
      eventName,
      {}
    );
  }
}
