/**
 * The possible image renditions in the beacon product service.
 */
 export enum ProplusImageRenditionTypes {
	swatch = 'swatch',
	small = 'small',
	hero = 'hero',
	thumb = 'thumb',
}

export type ProplusImageRenditionTypesOptions =
	| 'swatch'
	| 'small'
	| 'hero'
	| 'thumb';
