import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { getActiveVariations, IAttrOption, toSet, Variations } from 'src/app/models/variations.model';
import { TemplateViewModel } from 'src/app/pages/template-detail/template-view-model';
import { ProductsService } from 'src/app/services/products.service';
import { UserService } from 'src/app/services/user.service';
import { FacetSelector } from '../../pages/template-detail/facet-selector';
import { AddToTemplateComponent } from '../add-to-template/add-to-template.component';

@Component({
  selector: 'app-product-quick-add',
  templateUrl: './product-quick-add.component.html',
  styleUrls: ['./product-quick-add.component.scss'],
})
export class ProductQuickAddComponent implements OnInit {
  // activeVariation = {
  //   "color": [
  //       {
  //           "value": "Black",
  //           "enabled": true
  //       },
  //       {
  //           "value": "Hickory",
  //           "enabled": true
  //       },
  //       {
  //           "value": "Shakewood",
  //           "enabled": true
  //       },
  //       {
  //           "value": "Slate Blend",
  //           "enabled": true
  //       },
  //       {
  //           "value": "Tan",
  //           "enabled": true
  //       },
  //       {
  //           "value": "Weathered Wood",
  //           "enabled": true
  //       },
  //       {
  //           "value": "White",
  //           "enabled": true
  //       }
  //   ],
  //   "size": [
  //       {
  //           "value": "39-3/8\" x 34'",
  //           "enabled": true
  //       }
  //   ],
  //   "thickness": [
  //       {
  //           "value": "0.157\"",
  //           "enabled": true
  //       }
  //   ],
  //   "packaging": [
  //       {
  //           "value": "1 SQ. Roll",
  //           "enabled": true
  //       }
  //   ]
  // }
  // facetSelector = [
  //   { name: "color", selectedValue: "Black", value: "Black" },
  //   {name: "size", selectedValue: "'9-3/8\' x 34'", value: "'0.157\'"},
  //   {name: "thickness", selectedValue: "0.157", value:"0.157"},
  //   {name: "packaging", selectedValue: "1 SQ. Roll", value: "1 SQ. Roll"}
  // ]
  productItem: any;
  public quantity = 1;
  ctaClick: any;
  facetSelectors: { name: string; value: string }[];
  activeVariations: Record<string, IAttrOption[]>;
  product: any;
  public model!: TemplateViewModel;
  result: any;
  constructor(
    public navParams: NavParams,
    public modal: ModalController,
    public router: Router,
    private user: UserService,
    public loadingCtrl: LoadingController,
    private readonly productsService: ProductsService,
  ) {
    this.productItem = this.navParams.data.productItem;
    this.ctaClick = this.navParams.data.ctaClick;
    this.getItemDetail()
   }

  ngOnInit() {
    // console.log(this.productItem);
  }

  async getItemDetail(){
    let setDefaultFacet = true;
    const loading = await this.loadingCtrl.create();
		await loading.present();
    const queryParams = {
			productId: this.productItem.productId,
			accountId: this.user.session.accountId.toString()
		}
		const res = await this.productsService.getItemDetails(queryParams);
    this.result = res;
    this.product = res.product;
    const variationSet = toSet(res.variations);
    const filteredVariation = variationSet.filter(
      (v) => v.sku === res.product.itemNumber
    );
    const selectedFilters = getSelectedFiltersForSku(
      res.product.itemNumber,
      res.variations
    );
    const { facetOptions } = getActiveVariations(
      selectedFilters,
      res.variations
    );
    this.activeVariations = facetOptions;
    this.facetSelectors = Object.keys(res.variations)
      .filter((name) => (this.activeVariations[name] || []).length >= 1)
      .map((name) => {
        if (!setDefaultFacet) {
          return new FacetSelector(null, name, '');
        }
        const variationsForName = filteredVariation.filter(
          (fv) => fv.attr === name
        );
        const currVariation = res.currentVariations
          ? res.currentVariations[name]
          : null;
        const newValue =
          variationsForName.length > 0
            ? variationsForName.length === 1
              ? variationsForName[0].attrValue
              : currVariation
              ? currVariation
              : variationsForName[0].attrValue
            : '';
        return new FacetSelector(null, name, newValue);
      });
    loading.dismiss();
  }
  decreaseQty(){
    if (this.quantity > 1) {
      this.quantity--;
    }
  }
  increaseQty(){
    this.quantity++;
  }
  async presentTemplateModal() {
    // console.log('item', this.productItem);
    
		const modal = await this.modal.create({
			component: AddToTemplateComponent,
			swipeToClose: true,
			breakpoints: [0, 0.5, 0.75, 1],
			initialBreakpoint: 0.75,
      componentProps:{
        'productItem': this.productItem
      }
		} as any);
		return await modal.present();
	}

  async selectProduct(product: any){
    // console.log(this.result, 'product');
    
    if (!this.result) {
      return;
    }
    const quantity = this.quantity;
    const itemNumber = product.itemNumber;
    const unitOfMeasure = this.result.currentUOM;
    // console.log(this.model, 'model');
    
    await this.model.addItem({ itemNumber, quantity, unitOfMeasure });
  }
}
function getSelectedFiltersForSku(sku: string, variations: Variations) {
  const selection = new Map<string, string>();
  Object.entries(variations).forEach((v) => {
      const [facetName, attributes] = v;
      for (const [facetValue, skus] of Object.entries(attributes)) {
          const hasSku = skus.some((s) => s === sku);
          if (hasSku) {
              selection.set(facetName, facetValue);
          }
      }
  });
  return selection;
}