import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, throwError } from 'rxjs';
import { Http, HttpOptions } from '@capacitor-community/http';
import { environment } from 'src/environments/environment';
import { beaconServiceRoot } from '../constants/app.constants';
import { SessionInfo } from './session-info.service';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

export type ApiVersion = 'v1' | 'v2' | 'v3' | 'resourceApi';

@Injectable( {
  providedIn: 'root',
} )
export class ApiService {
  public readonly userSession = new BehaviorSubject<SessionInfo>(
    new SessionInfo( { tag: 'notInitialized' } )
  );

  constructor( private readonly http: HttpClient ) { }
  private readonly baseUrlV1 = environment.beaconV1ServiceUri;
  private readonly baseUrlV2 = environment.beaconV2ServiceUri;
  private readonly resourceApiUrl = environment.beaconResourceApiUri;
  private readonly baseUrlV3 = `${beaconServiceRoot}`;

  public getVersionUrl( version: ApiVersion ) {
    switch ( version ) {
      case 'v1':
        return this.baseUrlV1;
      case 'v2':
        return this.baseUrlV2;
      case 'v3':
        return this.baseUrlV3;
      case 'resourceApi':
        return this.resourceApiUrl;
    }
  }
  async get( version: ApiVersion, relpage: string, queryParams: any ) {
    const baseUrl = this.getVersionUrl( version );
    return Http.get( {
      url: `${baseUrl}/${relpage}${queryParams}`,
      webFetchExtra: {
        credentials: 'include',
      },
      headers: {
        'Content-Type': 'application/json',
        origin: 'capacitor://localhost',
      },
    } );
  }
  post( version: ApiVersion, relpage: string, data: any ): Observable<any> {
    const baseUrl = this.getVersionUrl( version );
    const options: HttpOptions = {
      url: `${baseUrl}/${relpage}`,
      headers: {
        'Content-Type': 'application/json',
        origin: 'capacitor://localhost',
      },
      method: 'POST',
      data: data,
      webFetchExtra: {
        credentials: 'include',
      },
    };
    return from( Http.request( options ) );
  }
  public getApiObservable<T>(
    version: ApiVersion,
    relPage: string,
    getParameters: any
  ) {
    const baseUrl = this.getVersionUrl( version );
    const url = `${baseUrl}/${relPage}`;
    const obs = this.http.get<T>( url, {
      withCredentials: true,
      params: getParameters,
    } );
    return obs.pipe(
      catchError( ( err ) => {
        if ( err instanceof HttpErrorResponse && err.status === 403 ) {
          return throwError(
            // new ForbiddenError(
            //     `You do not have access to this resouce`
            // )
            new Error( 'You do not have access to this resouce' )
          );
        }
        return throwError( err );
      } )
    );
  }
  getApiDataUrlBlob( version: ApiVersion, relpage: string, params: any ) {
    const baseUrl = this.getVersionUrl( version );
    const options: HttpOptions = {
      url: encodeURI( `${baseUrl}/${relpage}${params}` ),
      webFetchExtra: {
        credentials: "include",
      },
      responseType: 'blob',
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        origin: 'capacitor://localhost'
      },
    };
    // return await Http.get(options);
    return from( Http.request( options ) ).pipe( ( blob ) => blob )
  }

  getResourceApi( query?: string ) {
    const params = query ? `resourceapi${query}` : 'resourceapi';
    return Http.get( {
      url: `${environment.resourseApi}/${params}`,
      webFetchExtra: {
        credentials: 'include',
      },
      headers: {
        "Content-Type": "application/json",
        origin: 'capacitor://localhost'
      },
    } );
  }
}
