// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const host = 'localhost';
const { apiHost, apiPrefix, resourseApi, locationApi } = {
  apiHost: 'https://dev-static.becn.digital',
  apiPrefix: '/becn',
  resourseApi: 'https://dev-site.becn.digital',
  locationApi: 'https://dev.becn.digital/api',
};

// const { apiHost, apiPrefix, resourseApi, locationApi } = { apiHost: 'https://beacon-uat.becn.com', apiPrefix: '', resourseApi: 'https://uat-site.becn.digital', locationApi: 'https://uat.becn.digital/api', };
// const { apiHost, apiPrefix, resourseApi, locationApi } = {
//   apiHost: 'https://uat-static.becn.digital',
//   apiPrefix: '/becn',
//   resourseApi: 'https://uat-site.becn.digital',
//   locationApi: 'https://uat.becn.digital/api',
// };

// const { apiHost, apiPrefix, resourseApi, locationApi } = { 
//   apiHost: 'https://static.becn.com', 
//   apiPrefix: '/becn', 
//   resourseApi: 'https://site.becn.com',
//   locationApi: 'https://becn.com/api', 
// };

const googleMapsApiKey = 'AIzaSyA75YM--h6gCZHztioIkWI76_FspRUHXOc';
const mapUrl = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&`;

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyASl011oQCzUpjKLV7xdLaEnn-bvYv2cOw",
    authDomain: "development-220514.firebaseapp.com",
    databaseURL: "https://development-220514.firebaseio.com",
    projectId: "development-220514",
    storageBucket: "development-220514.appspot.com",
    messagingSenderId: "749868211879",
    appId: "1:749868211879:ios:f726ef1c4dc79b815dc582",
    measurementId: "",
  },
  devServer: false,
  uatServer: false,
  prodServer: false,
  local: true,
  hippoRootProto: 'http',
  hippoRootUrl: host,
  hippoCmsRootUrl: host,
  hippoSpaRootUrl: `${host}:4200`,
  hippoRootPort: '8080',
  hippoSitePrefix: 'site',
  cacheServiceRoot: `${apiHost}`,
  beaconServiceUri: `${apiPrefix}/rest/model/REST/NewRestService/v3/rest/com/becn`,
  beaconV1ServiceUri: `${apiHost}${apiPrefix}/rest/model/REST/NewRestService/v1/rest/com/becn`,
  beaconV2ServiceUri: `${apiHost}${apiPrefix}/rest/model/REST/NewRestService/v2/rest/com/becn`,
  beaconResourceApiUri: `https://site.becn.com`,
  imageServiceUri: '/insecure/plain',
  mapUrl: `${apiHost}/api/location?`,
  mapsKey: 'AIzaSyAXM1RLYbOKeITr_oyx6mcOozY7mEKQ-TQ',
  // blogApiPath: '/api/blog',
  resourseApi: `${resourseApi}`,
  locationApi: `${locationApi}/location?`,
  blogApiPath: 'https://beacon-api-x7xzzdp35a-uk.a.run.app/api/beacon-bits',
  timezoneUrl: `https://maps.googleapis.com/maps/api/timezone/json?key=${googleMapsApiKey}&`,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
