import { Component, OnInit, Input } from '@angular/core';
import { UtilService } from 'src/app/core/services/utils';

@Component({
  selector: 'app-order-item-summary',
  templateUrl: './order-item-summary.component.html',
  styleUrls: ['./order-item-summary.component.scss'],
})
export class OrderItemSummaryComponent implements OnInit {
  @Input() item: any;
  orderNote: any;
  constructor(public util: UtilService) { }

  ngOnInit() {
  }
  getOrderNote(item: any){
    return item && item.slice(0, -2).substr(2) || '';
  }
}
