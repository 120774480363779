import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AutoLoginGuard } from './core/guards/auto-login.guard';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import( './pages/login/login.module' ).then( ( m ) => m.LoginModule ),
    canLoad: [AutoLoginGuard], // add IntroGuard if neeeded
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import( './pages/welcome/welcome.module' ).then( ( m ) => m.WelcomeModule ),
  },
  {
    path: 'proplus',
    loadChildren: () =>
      import( './pages/tabs-page/tabs.module' ).then( ( m ) => m.TabsModule ),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: '/proplus/home',
    pathMatch: 'full',
  },
];
@NgModule( {
  imports: [
    RouterModule.forRoot( routes, { preloadingStrategy: PreloadAllModules } ),
  ],
  exports: [RouterModule],
} )
export class AppRoutingModule { }
