import { FcmService } from 'src/app/services/fcm.service';
import { CartService } from 'src/app/services/cart.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  ModalController,
  AlertController,
  PopoverController,
} from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../../core/services/api.service';
import { AccountDetails } from '../../models/account.model';
import { CurrentUser } from '../../models/user.model';
import { AnalyticsService } from '../../services/analytics.service';
import { UserService } from '../../services/user.service';
import { AccountSelectorModal } from './modal/account-selector-modal';
import { PopoverPage } from './notification-popover/notification-popover';
import { NotificationSelectorModalComponent } from './modal/notification-selector-modal.component';

@Component( {
  selector: 'app-account-selector',
  templateUrl: './account-selector.component.html',
  styleUrls: ['./account-selector.component.scss'],
} )
export class AccountSelectorComponent implements OnInit {
  currentUseraccountObs = new BehaviorSubject<any>( {} );
  unread = new BehaviorSubject<number>( 0 );

  notificationList = new BehaviorSubject<any>( [] );
  currentNotificationSelection = new BehaviorSubject<any>( {} );

  constructor(
    private user: UserService,
    public apiService: ApiService,
    public modal: ModalController,
    public alert: AlertController,
    public popoverCtrl: PopoverController,
    private cart: CartService,
    public analyticsService: AnalyticsService,
    private fcm: FcmService
  ) {

  }
  currentUser: CurrentUser;
  accounts: AccountDetails[];
  async ngOnInit() {
    this.loadAccounts();
    /* BADGE */
    this.fcm.notificationList$.subscribe( items => {
      console.log( { items } );
      this.notificationList.next( items );
      this.unread.next( items.length );
    } )
  }

  ionViewDidEnter() {
    // this.loadAccounts();  
  }
  async loadAccounts() {
    // console.log('users acount',this.user)
    this.user.userSession.subscribe( ( acct ) => {
      this.accounts = acct.accountList;
    } );

    this.user.currentUserAccount$.subscribe( ( currAccount ) => {
      console.log( { currAccount: currAccount } );
      this.currentUseraccountObs.next( currAccount );
      this.currentUser = this.currentUseraccountObs.value;
    } );


  }
  async presentAccountSelectorModal() {
    const modal = await this.modal.create( {
      component: AccountSelectorModal,
      swipeToClose: true,
      breakpoints: [0, 0.5, 0.75, 1],
      initialBreakpoint: 0.75,
      componentProps: {
        accounts: this.accounts,
        selectedAcc: this.currentUseraccountObs,
      },
    } as any );
    modal.onDidDismiss().then( ( data ) => {
      const accountSelected: any = data.data;

      if ( accountSelected?.isAccountClosed ) {
        this.alert
          .create( {
            header: 'Your account has been closed',
            message: `To open a new account, please contact the credit team at customercare-@becn.com or call 844-523-6514.`,
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                  // console.log( 'Confirm Cancel' );
                },
              },
              {
                text: `Call`,
                cssClass: 'open-contact',
                handler: () => {
                  window.open( 'tel:' + '844-523-6514' );
                },
              },
            ],
          } )
          .then( ( alert ) => alert.present() );
        return;
      }
      if ( accountSelected ) {
        this.selectAccount( accountSelected );
      }
    } );
    return await modal.present();
  }

  async presentNotificationSelectorModal() {
    const modal = await this.modal.create( {
      component: NotificationSelectorModalComponent,
      swipeToClose: true,
      breakpoints: [0, 0.5, 0.75, 1],
      initialBreakpoint: this.unread.value > 1 ? 0.75 : 0.475,
      /*  componentProps: {
         accounts: this.accounts,
         selectedAcc: this.currentUseraccountObs,
       }, */
    } as any );
    modal.onDidDismiss().then( ( notification ) => {
      // console.log( 'oldDismiss:', notification );
      const notificationData: any = notification.data;
      // console.log( { notificationData } );
      this.fcm.confirmSwitchAccount( notificationData.data.accountId );
    } );
    return await modal.present();
  }
  selectAccount( accountSelected: AccountDetails ) {
    this.alert
      .create( {
        header: 'Confirm',
        message: `Are you sure you want to select ${accountSelected.accountName}?, This will clear your cart.`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              // console.log( 'Confirm Cancel' );
            },
          },
          {
            text: 'Ok',
            handler: () => {
              this.changeAccount( accountSelected );
              this.cart.resetCartBadge();
              this.fcm.registerToken();
            },
          },
        ],
      } )
      .then( ( alert ) => alert.present() );
  }
  async changeAccount( accountSelected: AccountDetails ) {
    await this.user.setAccount( accountSelected );
  }

  trackEvents( eventName: string, source?: any ) {
    this.analyticsService.logEvent( eventName, {} );
  }
  async presentPopover( event: Event | any ) {
    console.log( { event } );

    this.trackEvents( 'notification_click' );
    const popover = await this.popoverCtrl.create( {
      component: PopoverPage,
      cssClass: 'notification-popover',
      event: event,
      translucent: true,
    } );
    await popover.present();
  }
}
