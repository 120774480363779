import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { OrderDetailResponse, OrderHistoryLineItem } from '../../models/orders.model';

@Injectable({
    providedIn: 'root'
})
  
export class UtilService {
    momentjs: any = moment;
    public tryParseDate(orderPlacedDate: string | undefined): string {
        let parsedDate = this.momentjs(orderPlacedDate, 'MM-DD-YYYY');
        if (parsedDate.isValid()) {
            return parsedDate.format('MM/DD/YYYY');
        }
        parsedDate = this.momentjs(orderPlacedDate);
        return parsedDate.format('MM/DD/YYYY');
    }

    public getLinesToAdd(orderDetailResponse: OrderDetailResponse) {
        return (orderDetailResponse.lineItems || []).map((i) => this.getProductToAdd(i))
          .filter((i) => i.itemNumber && i.quantity > 0
        );
      }
    
      public getProductToAdd(line: OrderHistoryLineItem) {
        const { productImageUrl, productId, itemNumber, unitOfMeasure, quantity, orderQuantity, shipQuantity } = line;
        const prodUrl = this.getProdUrl(line);
        const name = this.getName(line);
        const newQuantity = orderQuantity || shipQuantity || quantity;
        return {
          name,
          productId,
          itemNumber,
          prodUrl,
          productImageUrl,
          unitOfMeasure,
          quantity: newQuantity,
        };
      }
      public getProdUrl(item: OrderHistoryLineItem) {
        if (item && item.productId && item.itemNumber) {
          return ['/productDetail', item.productId, item.itemNumber];
        }
        if (item && item.productId) {
          return ['/productDetail', item.productId];
        }
        return [];
      }
      public getName(item: any) {
        if (item.skuPDPDisplayTitle) {
          return item.skuPDPDisplayTitle;
        }
        else{
          return item.itemOrProductDescription;
        }
      }
      public getItemQuantity( orderDetailResponse: OrderDetailResponse ) {
        return (orderDetailResponse.lineItems || []).filter((i) => 
          (i.itemNumber && i.orderQuantity) || i.shipQuantity || i.quantity > 0
        );
      }
}