import { Component, OnInit, Input } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { UtilService } from 'src/app/core/services/utils';
import { CartItems, ItemsEntity } from 'src/app/models/cart.model';
import { OrderDetailResponse, OrderHistoryLineItem } from 'src/app/models/orders.model';
import { CartService } from 'src/app/services/cart.service';
import { OrderHistoryService } from 'src/app/services/order-history.service';
import { ProductsService } from 'src/app/services/products.service';
import { ToastService } from 'src/app/services/toast.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss'],
})
export class OrderItemComponent implements OnInit {
  @Input() item: any;
  orderDetailResponse: OrderDetailResponse | null = null;
  constructor(
    private router: Router, 
    public util: UtilService, 
    public orderHistoryService: OrderHistoryService,
    public productService: ProductsService,
    public toast: ToastService,
    public loadingCtrl: LoadingController,
    private cartService: CartService,
    public modal: ModalController,
    private analyticsService: AnalyticsService,

  ) { }

  ngOnInit() {
  }
  openDetailsWithQueryParams() {
    this.analyticsService.logEvent(
      'view_order_details',
      {}
    );
    let navigationExtras: NavigationExtras = {
      queryParams: {
        orderId: this.item.orderId,
        accountId: this.item.accountId,
        accountToken: this.item.accountToken
      }
    };
    this.router.navigate(['/proplus/orders/detail'], navigationExtras);
  }

  async addOrderToCart() {
    this.analyticsService.logEvent(
      'add_all_to_cart',
      {}
    );
    const loading = await this.loadingCtrl.create();
    await loading.present();
    const queryParams = {
      orderId: this.item.orderId,
      accountId: this.item.accountId,
      accountToken: this.item.accountToken
    }
    const response = await this.orderHistoryService.getOrderHistoryDetail(queryParams);
    this.orderDetailResponse = response;
    if (!this.orderDetailResponse) {
      loading.dismiss();
      return;
    }
    const filteredItems = this.util.getItemQuantity(this.orderDetailResponse);
    const products = this.util.getLinesToAdd(this.orderDetailResponse);
    await this.productService.checkProductsBeforeAdding(
      products,
      'Cart',
      async (itemToAdd) => {
        if (!itemToAdd || itemToAdd.size === 0) {
          loading.dismiss();
          this.toast.showMessage('All items from this order are not available in your region', 5000)
        }
        const itemSetToAdd = new Set(itemToAdd);
        const items = filteredItems.filter((v) => itemSetToAdd.has(v.itemNumber) );
        const body = this.buildRequest(items);
       this.cartService.addItemsToCartFromOrder(body).subscribe((res: any) => {
          loading.dismiss();
          const itemsAdded = res.data.result.addedToCartItems;
          const resultMessage = itemsAdded.length
            ? `The following item(s) have been added to cart, ${itemsAdded.join(', ')}`
            : `Nothing was added to your cart`;
          this.toast.showMessage(resultMessage, 5000);
          this.modal.dismiss();
        })
      }
    );
  }

  buildRequest(lineItems: OrderHistoryLineItem[]): CartItems {
    if (!this.item.accountId) { 
      // throw new AppError('Invalid accoubnt'); 
      return;
    }
    return {
      addItemCount: lineItems.length,
      accountId: this.item.accountId.toString(),
      items: lineItems.map((line) => {
        const item: ItemsEntity = {
          catalogRefId: line.itemNumber,
          productId: line.productId,
          quantity: line.orderQuantity || line.shipQuantity || line.quantity,
          uom: line.unitOfMeasure,
          catalogRefIdChanged: false,
        };
        return item;
      }),
    };
  }
}
