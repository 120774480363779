import { Component, OnInit, Optional } from '@angular/core';
import { AnimationOptions } from '@ionic/angular/providers/nav-controller';
import { NavController, IonRouterOutlet } from '@ionic/angular';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {

  constructor(
    @Optional() private routerOutlet: IonRouterOutlet,
    public navCtrl: NavController,
  ) { }

  ngOnInit() {}
  back(){
    let animations:AnimationOptions={
      animated: true,
      animationDirection: "back"
    }
    if (this.routerOutlet && this.routerOutlet.canGoBack()) {
      this.navCtrl.setDirection("back");
      this.routerOutlet.pop();
    } else {
      this.navCtrl.navigateBack("/");
    }
    // this.navCtrl.back(animations)
  }

}
