import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { AllPermissions } from 'src/app/models/permissions.model';
import { UserService } from 'src/app/services/user.service';

 
@Directive({
  selector: '[appDisablePermission]'
})
export class DisablePermissionDirective {
 
  @Input() disableForPermission: string;
    userPerm: AllPermissions;
 
  constructor(private userService: UserService,
    private renderer: Renderer2,
    public element: ElementRef) { }
 
  ngAfterViewInit() {
    this.userPerm = this.userService.session.permissions;
    // debugger
    // console.log('userPerm', this.userPerm);
    // console.log('disableForPermission', this.disableForPermission);
    if (!this.userPerm.order[this.disableForPermission]) {
        this.renderer.setStyle(this.element.nativeElement, 'pointer-events', 'none');
        this.renderer.setStyle(this.element.nativeElement, 'opacity', 0.4);
    }
  }
}