import { ApiResult } from '../models/api.model'
import { GetCurrentUserInfoResponse } from '../models/user.model';

export function isApiResponse(object: any): object is ApiResult {
    return object && 'success' in object;
}

export const isLoggedIn = (user: GetCurrentUserInfoResponse | null) => {
    return user != null && user.profileId != null;
};

export const getParameterByName = (name: string, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}