import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DisablePermissionDirective } from './directives/disable-permission.directive';
import { ImagePreloadDirective } from './directives/image-preload.directive';
import { HasSubmitForApprovalPermissionDirective } from './directives/has-submit-for-approval-permission.directive';
import { HasPermissionDirective } from './directives/has-permission.directive';

@NgModule({
    declarations: [
        ImagePreloadDirective,
        HasSubmitForApprovalPermissionDirective,
        DisablePermissionDirective,
        HasPermissionDirective
    ],
    imports: [
    ],
    exports: [
        ImagePreloadDirective,
        HasSubmitForApprovalPermissionDirective,
        DisablePermissionDirective,
        HasPermissionDirective
    ],
})
export class SharedModule {}
