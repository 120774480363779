import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-confirm-available-products',
  templateUrl: './confirm-available-products.component.html',
  styleUrls: ['./confirm-available-products.component.scss'],
})
export class ConfirmAvailableProductsComponent implements OnInit {
  config: any;
  constructor(
    private navParams: NavParams,
    public modal: ModalController
  ) { }

  ngOnInit() {
    this.config = this.navParams.data;
    // console.log(this.config, 'availableItem');
    
  }

  addToCart() {
    const availableItems = new Set(
        this.config.availableLineItems.map((i) => i.itemNumber)
    );
    this.config.whenUserSaysOk(availableItems);
  }
}

export interface ProductConfirmationConfig {
  unavailableLineItems: ConfirmationProduct[];
  availableLineItems: ConfirmationProduct[];
  unavailableSkuList: string[];
  cartOrTemplate: string;
  whenUserSaysOk(items: Set<string>): void;
}

export interface ConfirmationProduct {
  name: string;
  productId: string;
  itemNumber: string;
  prodUrl: string[];
  productImageUrl: string;
}