import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController, LoadingController, PickerOptions, PickerController } from '@ionic/angular';

import { UpdateTemplateRequest } from '../../models/template.model';

import { TemplateService } from '../../services/template.service';
import { UserService } from '../../services/user.service';
import { UtilService } from '../../core/services/utils';
import { NavParams } from '@ionic/angular';

import { ProductsService } from '../../services/products.service';
import { ToastService } from 'src/app/services/toast.service';
import { format, parseISO } from 'date-fns';

@Component({
	selector: 'app-add-to-template',
	templateUrl: './add-to-template.component.html',
	styleUrls: ['./add-to-template.component.scss'],
})
export class AddToTemplateComponent implements OnInit {
	templateList: TemplateReference[] = [];
	accountId: number;
	recentTemplateView: any;
	clickMe: any;
	orderDetailResponse: any;
	pageNo = 1;
	productItem: any;
	searchTerms = [
		{
			name: 'Template Name',
			value: 'templateName',
			selected: true
		},
		{
			name: 'Account',
			value: 'account'
		},
		{
			name: 'Last Modified',
			value: 'dataLastModified'
		},
		{
			name: 'Created By',
			value: 'createdBy'
		}
	];
	showSearch: boolean = true;
	showDatePickers = false;
	searchLabel: string = 'Template Name';
	searchPlaceholder = `Enter ${this.searchLabel}`;
	selectedSearchValue: any;
	filter: any;
	lastModifiedDateValue: string;
	pageSize = 5;
	lastModifiedFormatedString: string;
	singleProductInfo: any;
	cartItem: any;

	constructor(
		public templateService: TemplateService,
		public user: UserService,
		public toast: ToastService,
		public util: UtilService,
		public productService: ProductsService,
		public navParams: NavParams,
		public modal: ModalController,
		public alert: AlertController,
		private pickerController: PickerController,
		public loadingCtrl: LoadingController,

	) { }

	async ngOnInit() {
		this.orderDetailResponse = this.navParams.data.orderDetailResponse;
		this.productItem = this.navParams.data.productItem;
		this.accountId = this.user.session.accountId
		this.cartItem = this.navParams.data.cartItem;
		console.log('cartItem', this.cartItem);
		
		// const queryParams = {
		//   accountId: this.accountId, 
		// };
		const queryParams = `?accountId=${this.accountId}&pageNo=${this.pageNo}&pageSize=${1000}&invokeBy=store&showPricing=false`;
		const response = await this.templateService.getAllTemplate(queryParams);
		this.recentTemplateView = response[0];
		// console.log('recentTemplateView', this.recentTemplateView)
		response.sort((a, b) => {
			if (a.templateName === b.templateName) {
				return 0;
			} else if (a.templateName < b.templateName) {
				return -1;
			} else {
				return 1;
			}
		});
		this.templateList = response;
		this.searchLabel = 'Template Name';
		this.searchPlaceholder = `Enter ${this.searchLabel}`;
		this.selectedSearchValue = 'templateName';
		this.singleProductInfo = this.navParams.get('singleProductInfo');
	}

	async addItemsToTemplate(selectedTemplateItem: TemplateReference) {
		if (this.orderDetailResponse) {
			const orderDetailResponse = this.orderDetailResponse;
			if (!orderDetailResponse) {
				return;
			}

			const products = this.util.getLinesToAdd(orderDetailResponse);

			await this.productService.checkProductsBeforeAdding(products, 'Template',
				async (itemToAdd) => {
					if (!itemToAdd || !itemToAdd.size) {
						this.toast.showMessage('All items from this order are not available in your region', 5000)
					}
					const itemSetToAdd = new Set(itemToAdd);
					const lineItems = products.filter((v) => itemSetToAdd.has(v.itemNumber)).map((v) => {
						return {
							templateItemId: v.productId,
							itemNumber: v.itemNumber,
							unitOfMeasure: v.unitOfMeasure,
							quantity: v.quantity,
						};
					}).filter((l) => l.quantity > 0);
					const accountId = selectedTemplateItem.accountLegacyId;
					const request: UpdateTemplateRequest = {
						account: accountId,
						action: 'createItem',
						templateId: selectedTemplateItem.templateId,
						templateName: selectedTemplateItem.templateName,
						items: lineItems,
					};
					this.templateService.updateTemplateItems(request).subscribe((res: any) => {
						const { status, data } = res;
						// console.log('res', res)
						if (status !== 200) {
							return;
						}
						const responseMessage = data.result && data.result.templateName ? data.result && data.result.templateName : data.messages && data.messages[0].value;
						const message = `${lineItems.length} Item(s) added to Template ${responseMessage}`;
						this.toast.showMessage(message, 5000);
						this.modal.dismiss({
							'dismissed': true
						});
					})
				}
			);
		}
		if (this.productItem) {
			const selectedTemplate = selectedTemplateItem;
			if (!selectedTemplate) {
				return;
			}

			const quantity = this.productItem.quantity;
			const uom = this.productItem.selectedUOM;
			const itemNumber = this.productItem.itemNumber;
			const lineItems = [
				{
					itemNumber: itemNumber,
					quantity: quantity,
					unitOfMeasure: uom,
				},
			];
			this.addToTemplate(selectedTemplateItem,lineItems);
		}
		if (this.singleProductInfo) {
			const selectedTemplate = selectedTemplateItem;
			if (!selectedTemplate) {
				return;
			}

			const quantity = this.singleProductInfo.quantity;
			const uom = this.singleProductInfo.selectedUOM;
			const itemNumber = this.singleProductInfo.itemNumber;
			const lineItems = [
				{
					itemNumber: itemNumber,
					quantity: quantity,
					unitOfMeasure: uom,
				},
			];
			this.addToTemplate(selectedTemplateItem,lineItems);
			
		}
		if(this.cartItem){
			const selectedTemplate = selectedTemplateItem;
			if (!selectedTemplate) {
				return;
			}
			const lineItems = this.cartItem.map((v) => {
				return {
					templateItemId: v.commerceItemId,
					itemNumber: v.catalogRefId,
					unitOfMeasure: v.uom,
					quantity: v.quantity,
				};
			});
			this.addToTemplate(selectedTemplateItem,lineItems);
		}
	}

	async addToTemplate(selectedTemplateItem,lineItems){
		const response = await this.templateService.addTemplateItems(
			selectedTemplateItem,
			lineItems
		);
		response.subscribe(res => {
			const { status, data } = res;
			if (status !== 200) {
				return;
			}
			const responseMessage = data.result && data.result.templateName ? data.result && data.result.templateName : data.messages && data.messages[0].value;
			const message = `${lineItems.length} Item(s) added to Template ${responseMessage}`;
			this.toast.showMessage(message, 5000);
			this.modal.dismiss({
				'dismissed': true
			})
		});
	}

	
	async presentAlertPrompt() {
		const alert = await this.alert.create({
			cssClass: 'my-custom-class',
			header: 'CREATE A TEMPLATE',
			inputs: [
				{
					name: 'templateName',
					type: 'text',
					placeholder: 'template name'
				},
			],
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
					cssClass: 'secondary',
					handler: () => {
						// console.log('Confirm Cancel');
					}
				}, {
					text: 'Create',
					handler: async (value: any) => {
						// console.log('Confirm Ok', value);
						const accountId = this.accountId;
						if (accountId === null) {
							throw new Error('No account is selected');
						}
						if (this.orderDetailResponse) {
							const orderDetailResponse = this.orderDetailResponse;
							if (!orderDetailResponse) {
								return;
							}

							const products = this.util.getLinesToAdd(orderDetailResponse);
							await this.productService.checkProductsBeforeAdding(
								products,
								'Template',
								async (itemToAdd) => {
									if (!itemToAdd || !itemToAdd.size) {
										this.toast.showMessage('All items from this order are not available in your region', 5000)
									}
									const lineItems = products.filter((v) => itemToAdd.has(v.itemNumber)).map((v) => {
										return {
											templateItemId: v.productId,
											itemNumber: v.itemNumber,
											unitOfMeasure: v.unitOfMeasure,
											quantity: v.quantity,
										};
									});
									this.createTemplate(value.templateName, accountId.toString(), lineItems)
									
								}
							);
						}
						if(this.cartItem){
							const lineItems = this.cartItem.map((v) => {
								return {
									itemNumber: v.catalogRefId,
									unitOfMeasure: v.uom,
									quantity: v.quantity,
									color: '',
									MFG: '',
								};
							});
							this.createTemplate(value.templateName, this.user.session.accountId.toString(), lineItems);
						}
						if (this.productItem) {
							const lineItems = [
								{
									itemNumber: this.productItem.itemNumber,
									unitOfMeasure:  this.productItem.selectedUOM,
									quantity: this.productItem,
									color: '',
									MFG: '',
								},
							];
							this.createTemplate(value.templateName, this.user.session.accountId.toString(), lineItems);
						}
						if(this.singleProductInfo){
							const lineItems = [
								{
									itemNumber: this.singleProductInfo.itemNumber,
									unitOfMeasure:  this.singleProductInfo.selectedUOM,
									quantity: this.singleProductInfo.quantity,
									color: '',
									MFG: '',
								},
							];
							this.createTemplate(value.templateName, this.user.session.accountId.toString(), lineItems);
						}
					}
				}
			]
		});

		await alert.present();
	}

	async createTemplate(templateName: string, accountId:string, items: any){
		this.templateService.createTemplate({
			templateName: templateName,
			account: accountId,
			items: items,
		}).subscribe((res => {
			const { status, data } = res;
			// console.log('res', res)
			if (status !== 200) {
				return;
			}
			const message = `${items.length} Item(s) added to Template ${data.result.templateName}`;
			this.toast.showMessage(message, 5000);
			this.modal.dismiss({
				'dismissed': true
			});
		}))
	}
	async showSearchOptions() {
		let options: PickerOptions = {
			buttons: [
				{
					text: "Cancel",
					role: 'cancel'
				},
				{
					text: 'Done',
					handler: (selected: any) => {
						this.showSearch = true;
						this.showDatePickers = false;
						this.searchLabel = selected.SearchType.text;
						this.searchPlaceholder = `Enter ${this.searchLabel}`
						this.selectedSearchValue = selected.SearchType.value
						if (this.selectedSearchValue === 'dataLastModified') {
							this.showDatePickers = true;
							this.showSearch = false;
						}
						picker.dismiss(selected, 'done');
					}
				}
			],
			columns: [{
				name: 'SearchType',
				selectedIndex: 2,
				options: this.getColumnOptions()
			}]
		};

		let picker = await this.pickerController.create(options);
		picker.present()
	}
	getColumnOptions() {
		let options = [];
		this.searchTerms.forEach(x => {
			options.push({ text: x.name, value: x.value });
		});
		return options;
	}
	async searchTemplate(event: any) {
		// const loading = await this.loadingCtrl.create();
		// await loading.present();
		this.filter = event.target && event.target.value || '';
		if (this.selectedSearchValue === 'dataLastModified') {
			this.filter = this.lastModifiedDateValue || ''
		}
		this.pageNo = 1;
		this.templateList = [];
		// console.log(event, 'search event');
		let queryParams = `?accountId=${this.user.session.accountId}&pageNo=${this.pageNo}&pageSize=${this.pageSize}&filterBy=${this.selectedSearchValue}&filter=${this.filter}&orderBy=&invokeBy=store&showPricing=false`
		const response = await this.templateService.getAllTemplate(queryParams);
		// console.log('template response', response);
		this.getTemplateData(response, '', '');
	}
	async getTemplateData(response: any, isFirstLoad: any, event: any) {
		const loading = await this.loadingCtrl.create();
		await loading.present();
		for (let i = 0; i < response.length; i++) {
			this.templateList.push(response[i]);
		}
		//get the subtotal for each template
		this.templateList.forEach((template) => {
			template.templateItems.forEach((item) => {
				template.subTotal = template.subTotal + item.unitPrice;
			});
		});

		if (isFirstLoad) {
			event.target.complete();
		}
		this.pageNo++;
		loading.dismiss();
	}
	clearSearch() {
		this.pageNo = 1;
		this.templateList = []
		this.getTemplate(false, "");
	}
	async getTemplate(isFirstLoad: boolean, event: any) {
		this.accountId = this.user.session.accountId; //|| await this.getSessionAccount();
		const queryParams = `?accountId=${this.accountId}&pageNo=${this.pageNo}&pageSize=${this.pageSize}&invokeBy=store&showPricing=false`;
		const response = await this.templateService.getAllTemplate(queryParams);
		// console.log('template response', response);
		this.getTemplateData(response, isFirstLoad, event)
	}
	lastModifiedDateChanged(value: any) {
		this.lastModifiedFormatedString = format(parseISO(value), 'MM/dd/yyyy');
		this.lastModifiedDateValue = format(parseISO(value), 'yyyy-MM-dd');
	}
	capitalizedAccountName(str: string) {
		return str.split('').map((letter, index) =>
			index ? letter.toLowerCase() : letter.toUpperCase(),
		).join('');
	}
	doInfinite(event: Event) {
		this.getTemplate(true, event);
	}
}
export interface CreatedByUser {
	firstName: string;
	lastName: string;
	emailAddress: string;
	cartLineItems?: any;
	internalUser: boolean;
	rewardsEligible: boolean;
	lastSelectedAccount?: any;
	profileId: string;
	userType?: any;
	accountBranch?: any;
}
export interface TemplateReference {
	accountName: string;
	createdByUser: CreatedByUser;
	lastModifiedDate: string;
	templateName: string;
	templateItems: TemplateItem[];
	accountLegacyId: string;
	isAccountClosed: boolean;
	lastModifiedUser: LastModifiedUser;
	templateId: string;
	subTotal: number;
}
export interface TemplateItem {
	itemNumber: string;
	itemType?: any;
	sendToMincronDirectly: boolean;
	vendorColorId?: any;
	pdpUrl?: any;
	available: boolean;
	description?: any;
	productOrItemNumber: string;
	subTotal: number;
	productOrItemDescription?: any;
	multiVariationData?: any;
	skusVariation?: any;
	variations?: any;
	imageUrl: ImageUrl;
	productImageUrl?: any;
	unitPrice: number;
	itemUnitPrice: number;
	quantity: number;
	itemOrProductDescription: string;
	unitOfMeasure: string;
	nickName?: any;
	templateItemId: string;
	productOnErrorImageUrl: string;
	productNumber?: any;
	defaultUnitOfMeasure: string;
	internalProductName: string;
	ATGItemMapping?: any;
	itemSubTotal: number;
	nonStockItem: boolean;
	sendDescriptionOnlyToMincron: boolean;
	lineComments?: any;
	extendedDescription?: any;
	hasBranchAvailability: boolean;
}
export interface LastModifiedUser {
	firstName: string;
	lastName: string;
	emailAddress: string;
	cartLineItems?: any;
	internalUser: boolean;
	rewardsEligible: boolean;
	lastSelectedAccount?: any;
	profileId: string;
	userType?: any;
	accountBranch?: any;
}
export interface ImageUrl {
	thumbnail: string;
	large: string;
	swatch: string;
}
