import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavigationExtras, Router } from '@angular/router';
import SwiperCore, { Pagination, Navigation, FreeMode, Thumbs } from 'swiper';

import { AddToTemplateComponent } from '../../components/add-to-template/add-to-template.component';
import { UserService } from '../../services/user.service';
import { ProductSku } from 'src/app/classes/product-sku';
import { environment } from 'src/environments/environment';
import { Image } from 'src/app/classes/image';
import { ProductQuickAddComponent } from '../product-quick-add/product-quick-add.component';

SwiperCore.use([Pagination, Navigation, FreeMode, Thumbs]);
@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductCardComponent implements OnInit {
  @Input() item: any;
  thumbsSwiper: any;
  colorOptionsArray: ProductSku[] = [];
  productImagesUrl:any[] = [];
  itemId: any;
  constructor(
    public modalController: ModalController,
    public router: Router,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.itemId = this.item.productId;
    if (this.item.skuList.length > 1) {
      this.generateSkuList(this.item);
      this.colorOptionsArray.push()
    }else{
      this.productImagesUrl=[];
      this.productImagesUrl.push(
        {image :`https://www.beaconproplus.com${this.item.productImage}`}
      )
    }
  }
  generateSkuList(item: any) {
    this.item.skuList.forEach((sku: any) => {
      // this.addToColorOptionsArray(sku);
      this.productImagesUrl=[];
      this.productImagesUrl.push(
        {image :`https://www.beaconproplus.com${item.productImage}`}
      )
      this.colorOptionsArray.push(sku);
      this.colorOptionsArray?.map((obj: any) => (
        this.productImagesUrl.push({
        ...obj, image: obj.image = `${environment.cacheServiceRoot}${environment.imageServiceUri}${obj.itemImage}`
      })));
    });
  }

  // addToColorOptionsArray(newColorOption: any) {
  //   this.colorOptionsArray.push(newColorOption);
  //   this.productImagesUrl = this.colorOptionsArray?.map((obj: any) => ({
	// 		...obj, image: obj.image = `${environment.cacheServiceRoot}${environment.imageServiceUri}${obj.itemImage}`
	// 	}));
    
  //   console.log('this.productImagesUrl', this.productImagesUrl);
    
  // }

  containsColorOption(newColorOption: ProductSku): boolean {
    return this.colorOptionsArray.some(
        (colorOption) =>
            colorOption.getSkuColor() === newColorOption.getSkuColor()
    );
  } 
  async presentModal(item: any, ctaClick: string) {
    // console.log('item', item);
    
		const modal = await this.modalController.create({
			component: ProductQuickAddComponent,
			swipeToClose: true,
			breakpoints: [0, 0.5, 0.75, 1],
			initialBreakpoint: 0.75,
      componentProps:{
        'ctaClick': ctaClick,
        'productItem': item
      }
		} as any);
		return await modal.present();
	}

  viewItemDetail(item: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        productId: item.productId || this.itemId,
        itemNumber: item.itemNumber,
        accountId: this.userService.session.accountId.toString(),
      }
    };
    this.router.navigate(['/proplus/product-detail'], navigationExtras);
  }


}
