import {
    Component,
    OnInit,
    Input,
    Renderer2,
    ElementRef,
    Inject,
    OnDestroy
  } from '@angular/core';
  import { DOCUMENT } from '@angular/common';
  import { environment } from 'src/environments/environment';
  import { LocationsService } from 'src/app/services/locations.service';
  import { Location } from 'src/app/classes/location';
  import { Subscription, Observable, BehaviorSubject } from 'rxjs';
  @Component({
    selector: 'app-googlemaps',
    templateUrl: './googlemaps.component.html',
    styleUrls: ['./googlemaps.component.scss'],
  })
  export class GooglemapsComponent implements OnInit, OnDestroy {
    apiKey: string = environment.mapsKey;
    mapLocationObj = new BehaviorSubject<any>({});
    public map: any;
    activeLabel = '';
    public markers: any[] = [];
    private mapsLoaded: boolean = false;
    private networkHandler = null;
    resultsList!: Location[];
    locationsSub!: Subscription;
    readonly mobileLat = 40.6532312;
    readonly mobileLong = -74.0107784;

    zoom = 8;
    maxZoom = 15;
    minZoom = 8;
    center: { lat: number; lng: number; };
  
    constructor(
      private renderer: Renderer2,
      private element: ElementRef,
      private readonly locationsService: LocationsService,
      @Inject(DOCUMENT) private doc: Document
    ) {}
  
    myLatLng = { lat: 40.6532312, lng: -74.0107784};// Map Options
    mapOptions: google.maps.MapOptions = {
        zoomControl: true,
        scrollwheel: false,
        maxZoom:this.maxZoom,
        minZoom:this.minZoom,
    };
  
    markerOptions: google.maps.MarkerOptions = { icon: '../../../../assets/img/finder-icon.png' };
    labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
    spots: {lat: number; lng: number }[] = [];
    selectMarker(spot) {
      alert(`hey! the spot with name ${spot.title} was seleted!`);
    }
    
    ngOnInit() {
      this.center = {
          lat: this.mobileLat,
          lng: this.mobileLong,
        }
       this.locationsSub = this.locationsService.locations$.subscribe(
            (newLocations) => {
                this.resultsList = newLocations;
                // console.log(this.resultsList, 'map resultsList');
                this.center =  Object.keys(this.locationsService.mapLocation.value).length !== 0 ? 
                this.locationsService.mapLocation.value : this.myLatLng;
            //  Object.keys(params).length !== 0
                // console.log('center', this.center);
            }
        );
    }

    ngOnDestroy() {
        this.locationsSub.unsubscribe();
    }
      
}
  