import { Component, OnInit, Input } from '@angular/core';
import { UtilService } from 'src/app/core/services/utils';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-cart-summary',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.scss'],
})
export class CartSummaryComponent implements OnInit {
  @Input() orderSummary: any;
  @Input() subtotal: any;

  get tax() {
    const summary = this.orderSummary;

    if (summary && summary.tax != 0) {
      return {
        value: this.currencyPipe.transform(summary.tax, 'USD'),
        cssClass: 'summary-text-right-default-number',
      };
    } else {
      return {
        value: 'Tax calculated at invoicing',
        cssClass: 'summary-text-right-default',
      };
    }
  }
  get otherCharges() {
    const summary = this.orderSummary;
    if (summary && !summary.displayOtherChargeTBD) {
      return {
        value: this.currencyPipe.transform(summary.otherChanges, 'USD'),
        cssClass: 'summary-text-right-default-number',
      };
    } else {
      return {
        value: 'Pending',
        cssClass: 'summary-text-right-default',
      };
    }
  }

  get total() {
    const summary = this.orderSummary;
    if (summary) {
      if (summary.totalMsg) {
        return {
          value: summary.totalMsg,
          cssClass: 'summary-text-total-default-right',
        };
      } else if (summary.total && !summary.displayOtherChargeTBD) {
        return {
          value: this.currencyPipe.transform(summary.total, 'USD'),
          cssClass: 'summary-text-total-default-right-number',
        };
      }
    }
    return {
      value: 'Will be calculated at the time of invoicing.',
      cssClass: 'summary-text-total-default-right',
    };
  }
  constructor(
    public util: UtilService,
    private readonly currencyPipe: CurrencyPipe
  ) {}

  ngOnInit() {}
}
