import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AllPermissions } from 'src/app/models/permissions.model';
import { UserService } from 'src/app/services/user.service';
 
@Directive({
  selector: '[appHasPermission]'
})
export class HasPermissionDirective {
 
  @Input('appHasPermission') permissions: string;
    userPerm: AllPermissions;
 
  constructor(
    private userService: UserService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) { }
 
  ngOnInit() {
    this.userPerm = this.userService.session.permissions;
    if (!this.userPerm.orderhistory[this.permissions]) {
        this.viewContainer.clear();
    }else{
        this.viewContainer.createEmbeddedView(this.templateRef);
    }
    // console.log('permissions', this.permissions)

    // if (this.hasPermission(this.permissions)) {
    //     
    // } else {
    //     this.viewContainer.clear();
    // }
  }
}
/**
 * 
 *  <ion-card *appHasPermission="['read']"></ion-card>
 */