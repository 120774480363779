import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-cart-map-pickup',
  templateUrl: './cart-map-pickup.component.html',
  styleUrls: ['./cart-map-pickup.component.scss'],
})
export class CartMapPickupComponent implements OnInit {
  constructor(public navParams: NavParams, public modal: ModalController) {}
  zip: string = '';
  ngOnInit() {
    this.zip = this.navParams.data.accountZip;
  }
  locations: any;

  dismissModal(location?: any) {
    if (location) {
      this.modal.dismiss(location);
    } else {
      this.modal.dismiss();
    }
  }
}
