import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { SplashScreen } from '@capacitor/splash-screen';
import { FcmService } from './services/fcm.service';
import { UserService } from './services/user.service';
import { ChatService } from './services/chat.service';
import { Keyboard } from '@capacitor/keyboard';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent {

	constructor(
		private platform: Platform,
		private router: Router,
		// private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		private fcmService: FcmService,
		private user: UserService,
		private zone: NgZone,
		private readonly chatService: ChatService
	) {
		this.initializeApp();
		// TODO if isNativePlatform then set Keyboard
		Keyboard.setAccessoryBarVisible({ isVisible: true })
	}

	ngOnInit() {
		// this.chatService.displayChat( false );
		// this.chatService.minimizeChat();
	}
	async initializeApp() {
		this.platform.ready().then(async () => {
			// this.statusBar.styleDefault();
			if (window.statusbar) {
				this.statusBar.overlaysWebView(false);
			}
			await SplashScreen.show({
				showDuration: 500,
				autoHide: true
			});
			this.fcmService.initPush();
		});

	}
}
