import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AutoLoginGuard implements CanLoad {
    constructor(private userService: UserService, private router: Router) { }
    canLoad(): Observable<boolean> {
      return this.userService.userSession.pipe(
        filter(val => val !== null), 
        take(1),
        map(state => {
          // console.log('state', state);
          if (state.isLoggedIn) {
            // Directly open inside area       
            this.router.navigateByUrl('/proplus', { replaceUrl: true });
          } else {          
            // Simply allow access to the login
            return true;
          }
        })
      );
    }
}