import { BehaviorSubject } from 'rxjs';
import { FcmService } from 'src/app/services/fcm.service';
import { Component } from '@angular/core';

import { PopoverController } from '@ionic/angular';

@Component( {
  styleUrls: ['./notification-popover.scss'],
  templateUrl: './notification-popover.html',
} )
export class PopoverPage {
  notificationList$ = new BehaviorSubject<any>( [] );
  constructor(
    public popoverCtrl: PopoverController,
    private fcm: FcmService
  ) { }

  ngOnInit(): void {
    /*   this.fcm.notificationList$.subscribe( notifications => {
        console.log( 'received new notification:', { notifications } );
  
        this.notificationList$.next( notifications );
        // console.log( 'this:', this.notificationList$.value );
      } ); */
  }

  navigatePush( notification: any ) {
    console.log( { notification } );
    this.fcm.confirmSwitchAccount( notification.data );
    this.popoverCtrl.dismiss();
  }

  support() {
    // this.app.getRootNavs()[0].push('/support');
    this.popoverCtrl.dismiss();
  }

  close( url: string ) {
    window.open( url, '_blank' );
    this.popoverCtrl.dismiss();
  }
}
