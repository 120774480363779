import { Injectable } from '@angular/core';
import { HttpResponse } from '@capacitor-community/http';

import { ApiService } from '../core/services/api.service';

import {
  CreateTemplateRequest,
  TemplateReference,
  UpdateTemplateRequest,
} from '../models/template.model';
import { tap } from 'rxjs/operators';
@Injectable( {
  providedIn: 'root',
} )
export class TemplateService {
  url: string;
  queryParams: string;

  templateStartRecord = [];
  constructor( public apiService: ApiService ) { }

  async getAllTemplate( queryOptions: any ) {
    const response: HttpResponse = await this.apiService.get(
      'v2',
      'template',
      queryOptions
    );
    return response.data.result.templates;
  }

  async getTemplateDetail( queryOptions: any ) {
    const queryParams = `?${Object.keys( queryOptions ).map( key => key + '=' + queryOptions[key] ).join( '&' )}`;
    const response: HttpResponse = await this.apiService.get(
      'v2',
      'getTemplateDetail',
      queryParams
    );

    this.templateStartRecord = response.data && response.data.result.templateItems;
    const record = this.templateStartRecord;
    console.log( { record } );
    return response.data;
  }

  async updateTemplate( request: UpdateTemplateRequest ) {
    console.log( 'updateTemplate', { request } );
    const parameters = {
      invokeBy: 'store',
      ignoreInvalidItems: 'false',
    };

    return this.apiService.post( 'v2', 'updateTemplate', request ).pipe(
      tap( ( res ) => {
        return res.data.result;
      } )
    );
  }
  updateTemplateItems( request: UpdateTemplateRequest ) {
    console.log( 'updateTemplateItems', { request } );
    const queryParameters: Record<string, string | boolean> = {};
    if ( request.action === 'updateItem' ) {
      if ( request.invokeBy ) {
        queryParameters.invokeBy = request.invokeBy;
        delete request.invokeBy;
      }
      if ( request.ignoreInvalidItems ) {
        queryParameters.ignoreInvalidItems = request.ignoreInvalidItems;
        delete request.ignoreInvalidItems;
      }
      this.queryParams = `?${Object.keys( queryParameters ).map( key => key + '=' + queryParameters[key] ).join( '&' )}`;
    }
    return this.apiService.post( 'v2', `updateTemplate${this.queryParams ? this.queryParams : ''}`, request ).pipe(
      tap( ( res ) => {
        return res.data.result;
      } )
    );
  }
  createTemplate( request: CreateTemplateRequest ) {
    return this.apiService.post( 'v2', 'createTemplate', request ).pipe(
      tap( ( res ) => {
        return res.data.result;
      } )
    );
  }

  async getSuggestiveSelling( req: SuggestiveSellingRequest ) {
    const response: HttpResponse = await this.apiService.get(
      'v2',
      'suggestiveSelling',
      `?account=${req.account}&itemNumber=${req.itemNumber}&pageSize=${req.pageSize}&pageNo=${req.pageNo}`
    );
    return response.data.result;
  }

  async addItemsToTemplate( req: AddItemsToTemplateRequest ) {
    const response = await this.apiService.post( 'v2', 'updateTemplate', req );
    return response;
  }
  public async addTemplateItems(
    selectedTemplate: TemplateReference,
    lineItems: {
      templateItemId?: string;
      itemNumber: string;
      unitOfMeasure: string;
      quantity: number;
    }[]
  ) {
    const accountId = selectedTemplate.accountLegacyId;
    const request: UpdateTemplateRequest = {
      account: accountId,
      action: 'createItem',
      templateId: selectedTemplate.templateId,
      templateName: selectedTemplate.templateName,
      items: lineItems,
    };
    console.log( 'addTemplateItems', { request } );
    return this.updateTemplateItems( request );
    // return this.apiService.post('v2', `updateTemplate`, request).pipe(
    //   tap((res) => {
    // 		console.log('UpdateTemplateRes', res.data.result)
    //     return res.data.result;
    //   })
    // );
  }
}

export interface SuggestiveSellingItem {
  productId: string;
  productName: string;
  productNameNotTruncate: string;
  pdpUrl: string;
  defaultItem: {
    itemNumber: string;
    unitOfMeasure: string;
    price: 0;
    vendorColorId: string;
    itemImage: string;
    itemOnErrorImage: string;
  };
}

export interface SuggestiveSellingObject {
  accountId?: string;
  templateId?: string;
  templateName?: string;
  type: EnumType;
  suggestiveItems: SuggestiveSellingItem[];
}

export type EnumType = 'template' | 'cart';

export interface SuggestiveSellingRequest {
  account?: string;
  // brandName?: string;
  // templateId?: string;
  itemNumber: string[];
  // excludeCategory?: string[];
  // excludeBrand?: string[];
  jobNumber?: number;
  pageSize: number;
  pageNo: number;
}

export interface AddItemsToTemplateRequest {
  templateId: string;
  templateName: string;
  account: string;
  lineItems: {
    templateItemId?: string;
    itemNumber: string;
    unitOfMeasure: string;
    quantity: number;
  }[];
}
