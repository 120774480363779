import { Component, Input, OnInit } from '@angular/core';
import { CurrentUser } from 'src/app/models/user.model';
import { CartService } from 'src/app/services/cart.service';
import {
  AddItemsToTemplateRequest,
  SuggestiveSellingItem,
  SuggestiveSellingObject,
  TemplateService,
} from 'src/app/services/template.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-suggested-items',
  templateUrl: './suggested-items.component.html',
  styleUrls: ['./suggested-items.component.scss'],
})
export class SuggestedItemsComponent implements OnInit {
  @Input() suggestiveItems: SuggestiveSellingObject;
  userDetails: CurrentUser;
  constructor(
    private cart: CartService,
    private userService: UserService,
    private templateService: TemplateService
  ) {}

  async ngOnInit() {
    this.userDetails = await this.userService.getSessionInfo();
  }

  async addItemsByType(item: SuggestiveSellingItem) {
    if (this.suggestiveItems.type === 'template') {
      await this.addItemsToTemplate(item);
    } else if (this.suggestiveItems.type === 'cart') {
      await this.addItemsToCart(item);
    }
  }

  async addItemsToCart(item: SuggestiveSellingItem) {

    const items: Item[] = [
      {
        catalogRefId: item.defaultItem.itemNumber,
        productId: item.productId,
        quantity: 1,
        uom: item.defaultItem.unitOfMeasure,
        catalogRefIdChanged: false,
      },
    ];
    const req: AddItemRequest = {
      addItemCount: 1,
      accountId: this.userDetails.lastSelectedAccount.accountLegacyId,
      items: items,
    };
    const response = await this.cart.addCartItems(req);
    console.log('addItemsToCart response', response);
  }

  async addItemsToTemplate(item: SuggestiveSellingItem) {
    const req: AddItemsToTemplateRequest = {
      templateId: this.suggestiveItems.templateId,
      templateName: this.suggestiveItems.templateName,
      account: this.userDetails.lastSelectedAccount.accountLegacyId,
      lineItems: [
        {
          templateItemId: item.productId,
          itemNumber: item.defaultItem.itemNumber,
          unitOfMeasure: item.defaultItem.unitOfMeasure,
          quantity: 1,
        },
      ],
    };
    const response = await this.templateService.addItemsToTemplate(req);
    console.log('addItemsToTemplate response', response);
  }
}
export interface AddItemRequest {
  addItemCount: number;
  accountId: string;
  specialInstructions?: string;
  items: Item[];
  jobNumber?: string;
  shipping?: Shipping;
}

export interface Item {
  catalogRefId: string;
  productId: string;
  catalogRefIdChanged: boolean;
  quantity: number;
  uom: string;
  matchColor?: string;
  matchMFG?: string;
  productNumber?: string;
  itemType?: string;
  description?: string;
}

export interface Address {
  address1: string;
  address2: string;
  city: string;
  postalCode: string;
  state: string;
}

export interface Shipping {
  shippingMethod: string;
  shippingBranch: string;
  address: Address;
}
