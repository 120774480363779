import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { BehaviorSubject } from 'rxjs';
import { FcmService } from 'src/app/services/fcm.service';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component( {
  selector: 'app-notification-selector-modal',
  templateUrl: './notification-selector-modal.component.html',
  styleUrls: ['./notification-selector-modal.component.scss'],
} )
export class NotificationSelectorModalComponent implements OnInit {
  messages = new BehaviorSubject<any[]>( [] );
  recent = new BehaviorSubject<any>( {} );
  currSelAccountName = 'My Account Name';

  constructor(
    public modal: ModalController,
    private readonly fcm: FcmService,
    private user: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    this.fcm.notificationList$.subscribe( list => {
      console.log( { list } );
      this.currSelAccountName = this.user.session.lastSelectedAccount.accountName;
      console.log( 'this.currSelName', this.currSelAccountName );

      this.recent.next( list[0] );
      this.messages.next( list.slice( 1, list.length ) );

      console.log( 'this.recent,', this.recent.value );
    } );
  }
  /**
    * accountId is checked for comparison.
    */
  async checkSameAccount( accountId: string ) {
    await this.fcm.confirmSwitchAccount( accountId );
    return this.fcm.isOrderInCurrAccount( accountId );
  }

  navigateToPage( notification: any ) {
    console.log( 'navigateToPage:', { notification } );
    this.modal.dismiss( notification );
  }

  dismissModal( account?: any ) {
    if ( account ) {
      this.modal.dismiss( account );
    } else {
      this.modal.dismiss();
    }
  }

}
