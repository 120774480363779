import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[appHasSubmitForApprovalPermission]'
})
export class HasSubmitForApprovalPermissionDirective {
    private visible = false;
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
    ) { }

    @Input() set appHasSubmitForApprovalPermission(hasSubmitForApproval: boolean) {
        if (hasSubmitForApproval && !this.visible) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.visible = true;
        } else if (!hasSubmitForApproval && this.visible) {
            this.viewContainer.clear();
            this.visible = false;
        }
    };
}

/** Implementation of this directive on page: 
    1. Add this to component ts => @Input() hasSubmitForApproval: boolean;
    2. Add this to ngOnInit() => this.hasSubmitForApproval = this.user.session.permissions.order.submitForApproval;
    3. Add this custom directive to the template to show or hide the element according to user's submitForApproval permission.
    Example:      
    <ion-button 
        size="large" 
        expand="block" 
        class="beacon-btn beacon-btn--white" 
        *appHasSubmitForApprovalPermission="hasSubmitForApproval"  //use hasSubmitForApproval or !hasSubmitForApproval
    >
    Submit for Approval
    </ion-button>
*/
