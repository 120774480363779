import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { AccountDetails } from 'src/app/models/account.model';

@Component( {
  selector: 'app-account-selector-modal',
  templateUrl: './account-selector-modal.html',
  styleUrls: ['./account-selector-modal.scss'],
} )
export class AccountSelectorModal implements OnInit {
  accounts: any;
  currentUser: BehaviorSubject<any>;
  constructor( public navParams: NavParams, public modal: ModalController ) { }

  async ngOnInit() {
    this.accounts = this.navParams.data.accounts;
    this.currentUser = this.navParams.data.selectedAcc;
  }
  dismissModal( account?: AccountDetails ) {
    if ( account ) {
      this.modal.dismiss( account );
    } else {
      this.modal.dismiss();
    }
  }
}
