import { Injectable } from '@angular/core';
import { HttpResponse } from '@capacitor-community/http';

import { SessionInfo } from '../core/services/session-info.service';
import { ApiService } from '../core/services/api.service';

/*** FIX ME
 *  - rename this service to orders.services.ts
 * 
 */

@Injectable( {
  providedIn: 'root'
} )
export class OrderHistoryService {
  url: string;
  queryParams: string;
  constructor(
    public apiService: ApiService,

  ) { }

  async getOrderHistory( queryOptions: any ) {
    // FIXME - this is confusing (works for now)
    // this.queryParams = `?${Object.keys(queryOptions).map(key => key + '=' + queryOptions[key]).join('&')}`;
    let queryParams = `?accountId=${queryOptions.accountId}&pageNo=${queryOptions.pageNo}&pageSize=${queryOptions.pageSize}`;
    if ( queryOptions.searchBy && queryOptions.searchTerm ) {
      queryParams = `?accountId=${queryOptions.accountId}&pageNo=${queryOptions.pageNo}&pageSize=${queryOptions.pageSize}&searchBy=${queryOptions.searchBy}&searchTerm=${queryOptions.searchTerm}`;
    } else if ( queryOptions.searchEnum ) {
      queryParams = `?accountId=${queryOptions.accountId}&pageNo=${queryOptions.pageNo}&pageSize=${queryOptions.pageSize}&searchBy=${queryOptions.searchBy}&searchEnum=${queryOptions.searchEnum}`;
    } else if ( queryOptions.searchStartDate && queryOptions.searchEndDate ) {
      queryParams = `?accountId=${queryOptions.accountId}&pageNo=${queryOptions.pageNo}&pageSize=${queryOptions.pageSize}&searchBy=${queryOptions.searchBy}&searchStartDate=${queryOptions.searchStartDate}&searchEndDate=${queryOptions.searchEndDate}`;
    }
    const response: HttpResponse = await this.apiService.get( 'v2', 'orderhistory_v2', queryParams );
    console.log( { queryParams } )
    return response.data;
  }

  async getOrderHistoryDetail( queryOptions: any ) {
    const queryParams = `?orderId=${queryOptions.orderId}&accountId=${queryOptions.accountId}&accountToken=${queryOptions.accountToken}`;
    const response: HttpResponse = await this.apiService.get( 'v2', 'orderdetail', queryParams );
    return response.data.result;
  }

  async downloadOrderDetailAsPDF( queryOptions: any ) {
    const queryParams = `?orderId=${queryOptions.orderId}&accountId=${queryOptions.accountId}&accountToken=${queryOptions.accountToken}&showPrice=${queryOptions.showPrice}`;
    return this.apiService.getApiDataUrlBlob( 'v2', 'downloadOrderDetailAsPDF', queryParams );
    // return response.data;
  }

  async getDTOrderDetail( queryOptions: any ) {
    const queryParams = `?orderId=${queryOptions.orderId}`;
    const response = await this.apiService.get( 'v2', 'getDTOrderDetail', queryParams );
    return response.data;
  }

  async getUpComingDeliveries( queryOptions: any ) {
    const queryParams = `?${Object.keys( queryOptions ).map( key => key + '=' + queryOptions[key] ).join( '&' )}`;
    const response = await this.apiService.get( 'v2', 'upcomingDeliveries', queryParams );
    return response.data;
  }

}

