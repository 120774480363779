import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { Drivers, Storage } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { ComponentModule } from './components/component.module';
import { SwiperModule } from 'swiper/angular';
import { CurrencyPipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { NotificationSelectorModalComponent } from './components/account-selector/modal/notification-selector-modal.component';



@NgModule( {
  declarations: [AppComponent, NotificationSelectorModalComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot( {
      rippleEffect: true,
      mode: 'ios',
    } ),
    IonicStorageModule.forRoot( {
      driverOrder: [Drivers.SecureStorage, Drivers.IndexedDB, Drivers.LocalStorage]
    } ),
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    ComponentModule,
    SwiperModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    FileOpener,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    SwiperModule,
    CurrencyPipe,
    SafeHtmlPipe
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
} )
export class AppModule { }
