import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-attribute-variations',
  templateUrl: './attribute-variations.component.html',
  styleUrls: ['./attribute-variations.component.scss'],
})
export class AttributeVariationsComponent implements OnInit {

	public items = [
		{ src: "https://static.becn.com/insecure/plain/images/large/635004_default_small.jpg",
		  name: "Barkwood",
		  code: "701"
		},
		{ src: "https://static.becn.com/insecure/plain/images/large/656437_default_small.jpg",
		  name: "Hickory" ,
		  code: "702"
		},
		{ src: "https://static.becn.com/insecure/plain/images/large/656448_default_small.jpg",
		  name: "Pewter Grey",
		  code: "703"
		},
		{ src: "https://static.becn.com/insecure/plain/images/large/664864_default_small.jpg",
		  name: "Antique Slate" ,
		  code: "703"
		}
	]

	attrName: string = 'Color';

  constructor() { }

  ngOnInit() {}

}
