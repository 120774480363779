import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProductsService } from 'src/app/services/products.service';

@Component({
	selector: 'app-product-detail-button',
	templateUrl: './product-detail-button.component.html',
	styleUrls: ['./product-detail-button.component.scss'],
})
export class ProductDetailButtonComponent implements OnInit {
	urlPath?: any;
	subscription: Subscription;
	constructor(
		private productsService: ProductsService,
		public router: Router
	) { }

	ngOnInit() {
	}

	async getUrlPath() {
			this.subscription = this.productsService.geturlPath().subscribe({
				next: path => this.urlPath = path,
				error: err => console.log('Error fetching url path', err)
			});			
			let navigationExtras: NavigationExtras = this.urlPath;
			await this.router.navigate(['/proplus/product-detail'], navigationExtras);
	}

	ngOnDestry() {
		this.subscription.unsubscribe();
	}
}
