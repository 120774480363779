import { OrderHistoryService } from 'src/app/services/order-history.service';
import { CurrentUser } from './../models/user.model';
import { CartService } from './cart.service';
import { ApiService } from './../core/services/api.service';
import { catchError, retryWhen, shareReplay } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { Injectable } from '@angular/core';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { NavigationExtras, Router } from '@angular/router';
import { FCM } from '@capacitor-community/fcm';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
} from '@capacitor/push-notifications';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { DeliveryNotificationSettingsModel } from '../models/delivery-notification-settings-model';
import { Capacitor } from '@capacitor/core';
import { Http, HttpOptions } from '@capacitor-community/http';
import { AccountDetails } from './account.service';

/* IOS Push iOS notifications Simulate to emulator
 -- xcrun simctl push <device-identifier> com.bundle-identifier-my-app ExamplePush.apns
 -- this worked -- xcrun simctl push booted com.becn.proplusapp notifications.apn (for the curr emulator running)
 */
@Injectable( {
  providedIn: 'root'
} )
export class FcmService {
  public plt: string;
  // token: string;
  badToken: string[] = [];
  fcmToken: string;
  currListOfTokens: string[];
  granted = false;
  isNative = Capacitor.isNativePlatform();
  private userOptedIn = new BehaviorSubject<boolean>( false );
  public userOptedIn$ = this.userOptedIn.asObservable();
  notificationsPerAccount = {
    nonActiveAccts: [],
    activeAcct: []
  }
  dtSettings = [];
  accounts: AccountDetails[];
  currentUseraccountObs = new BehaviorSubject<any>( {} );
  currentUser: CurrentUser;
  private badgeCount = new BehaviorSubject<number>( null );
  public badgeCount$ = this.badgeCount.asObservable();

  private notificationList = new BehaviorSubject<any[]>( [] ); // TODO types
  public notificationList$ = this.notificationList.asObservable();

  private deliveryNotificationSettings = new BehaviorSubject<DeliveryNotificationSettingsModel>(
    { received: null, delivered: null, ordered: null, scheduled: null, rescheduled: null } );
  public deliveryNotificationSettings$ = this.deliveryNotificationSettings.asObservable();
  constructor(
    private platform: Platform,
    public router: Router,
    private apiService: ApiService,
    public modal: ModalController,
    public alert: AlertController,
    private user: UserService,
    private cart: CartService,
    private orderHist: OrderHistoryService

  ) {
    this.plt = this.platform.is( 'mobileweb' ) ? 'web' : this.platform.is( 'ios' ) ? 'ios' : 'android';

    this.badgeCount.next( 0 );
    console.log( 'Native:', Capacitor.isNativePlatform() );
  }

  async initPush() {
    // alert( 'Push registration success, token: ' + JSON.stringify( 'fcm_token' ) );
    if ( this.isNative ) {
      this.registerPush();
    }
  }
  private async registerPush() {
    PushNotifications.requestPermissions()
      .then( async requestPermissions => {

        if ( requestPermissions.receive === 'granted' ) {
          PushNotifications.register();
          this.granted = true;

          const userOpted_In = await this.checkUserOptIn();
          console.log( { requestPermissions } );

          if ( userOpted_In ) {
            // TOOD: await FCM.deleteInstance(); 

            // TODO token management get FCM Token
            const { token: fcm_token } = await FCM.getToken();
            this.fcmToken = fcm_token;
            // alert( 'Push registration success, token: ' + JSON.stringify( fcm_token ) );

            /* Reigister */
            this.registerToken();
          };

        } else {
          // TODO handle errors Show some error
          this.granted = false;
          console.log( 'not optedIn:', this.fcmToken, this.currListOfTokens );
          console.log( 'requested permission denied' );
        }
      } )

    // On success, we should be able to receive notifications
    PushNotifications.addListener( 'registration', async ( { value } ) => {
      console.log( 'Listener: registration', { value } );

      const { token: fcm_token } = await FCM.getToken();
      this.fcmToken = fcm_token;
      this.registerToken();

    } );

    // Some issue with our setup and push will not work
    PushNotifications.addListener( 'registrationError',
      ( error: any ) => {
        console.log( 'Error: ' + JSON.stringify( error ) );
        // TODO what do we do for errors, this rarely happens
        // alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener( 'pushNotificationReceived',
      async ( notification: PushNotificationSchema ) => {
        console.log( { notification } );
        // console.log( 'pushNotificationReceived: ' + JSON.stringify( notification ) );

        // DETAILS get details from notificaion.data
        // const { body, badge, subtitle, title, data: { email, orderId, accountId, accountToken, aps, status } } = notification && notification.data;
        // console.log( 'data', {data} );

        await this.getDeliveryTrackingSettings();
        // Dont add notification to the list if the user has not opted in for 'received, delivered, ordered, scheduled'
        // RESCHEDULED does rescheduled need to go on list of DT settings

        const currNotficiations = this.notificationList.value;
        const { rescheduled, scheduled, ordered, received } = this.deliveryNotificationSettings.value;

        /* MAKE sure received, ordered, scheduled and rescheduled are coming from the BE */
        if ( notification.data.status === 'ordered' && ordered ) { // CONFUSING same as Placed Order?
          console.log( 'notif.data.status', notification.data.status );
          console.log( { ordered } );

          // push new notification to list in first position
          currNotficiations.unshift( notification )
          this.notificationList.next( currNotficiations );
        }
        if ( notification.data.status === 'received' && received ) { // CONFUSING same as Placed Order?
          console.log( 'notif.data.status', notification.data.status );
          console.log( { received } );

          // push new notification to list in first position
          currNotficiations.unshift( notification )
          this.notificationList.next( currNotficiations );
        }
        if ( notification.data.status === 'scheduled' && scheduled ) {
          console.log( 'notif.data.status', notification.data.status );
          console.log( { scheduled } );

          // push new notification to list in first position
          currNotficiations.unshift( notification )
          this.notificationList.next( currNotficiations );
        }
        if ( notification.data.status === 'rescheduled' && rescheduled ) {
          console.log( 'notif.data.status', notification.data.status );
          console.log( { rescheduled } );

          // push new notification to list in first position
          currNotficiations.unshift( notification )
          this.notificationList.next( currNotficiations );
        }
        console.log( '***notificationList', this.notificationList.value );

        // TODO handle badge if notification is counted
        const count = this.notificationList.value.length;
        this.badgeCount.next( count );

        /* Perform switch account behavior */
        if ( notification.data ) {
          this.isOrderInCurrAccount( notification.data.accountId );
        }
      }
    );



    // Method called when tapping on a notification
    PushNotifications.addListener( 'pushNotificationActionPerformed',
      ( notification: ActionPerformed ) => {
        const data = notification.notification.data;

        console.log( 'pushNotificationActionPerformed', { data } );
        // console.log( 'pushNotificationActionPerformed: ' + JSON.stringify( notification ) );

        if ( data && this.isOrderInCurrAccount( data.accountId ) ) {
          /*  let navigationExtras: NavigationExtras = {
             queryParams: {
               orderId: data.orderId,
               accountId: data.accountId,
               accountToken: data.accountToken // HEMA now
             }
           };
           this.router.navigate( ['/proplus/orders/detail'], navigationExtras ) */
          this.router.navigate( ['/proplus/orders'] );
        } else {
          this.confirmSwitchAccount( data );
        }
      }
    );
  }

  saveToken() {
    console.log( 'token:', this.fcmToken );

    /* Payload format */
    const body = {
      deviceToken: {
        tokens: [this.fcmToken],
        email: 'tim.fosque@becn.com',
        accountId: '280381'
      }
    }

    this.apiService.post(
      'v2',
      'updateMobileDevice',
      body
    )
      .pipe(
        shareReplay( 1 ),
        catchError( err => {
          if ( err ) {
            console.log( 'updateMobileDeviceV2Error:', { err } );
          }
          throw err;
        } )
      )
      .subscribe( updateMobileDeviceResponse => {
        if ( updateMobileDeviceResponse.data.success = false ) {
          console.log( 'condition:', updateMobileDeviceResponse.data );
        }
        console.log( 'reponse', { updateMobileDeviceResponse } );
      } ),
      // ERROR user account observable error handling
      catchError( err => {
        if ( err ) {
          console.log( 'level2:', 'currentUserAccountError:', { err } );
        }
        throw err;
      } )
  }

  public async registerToken() {
    // IMPORTANT make sure this is firing or registration will not happen
    this.user.currentUserAccount$
      .subscribe( async currentUserAccount => {
        this.currListOfTokens = currentUserAccount.deviceTokens;
        // console.log( { currentUserAccount } );
        if ( currentUserAccount === undefined ) return;

        /* ISBAD Token check */ // const isBad = await this.isBadToken( this.token );
        const badToken = await this.sendDryRunNotification( this.fcmToken );
        console.log( { badToken } );


        const isDuplicate = await this.checkDuplicateTokens( this.fcmToken, currentUserAccount.deviceTokens );
        console.log( { isDuplicate } );
        if ( isDuplicate ) return;

        // console.log( 'currUserAcct', currentUserAccount, 'currUsrAcct.deviceTokens', currentUserAccount.deviceTokens )
        if ( !currentUserAccount && !currentUserAccount.deviceTokens ) return;

        /* add new token */
        let addTokens = [this.fcmToken];

        /* Combine old tokens with new */
        if ( this.currListOfTokens !== null || this.currListOfTokens.length > 0 ) {
          addTokens = [...this.currListOfTokens, ...addTokens];
          console.log( { addTokens } );
        };

        /* Payload format */
        const body = {
          deviceToken: {
            accountId: currentUserAccount && currentUserAccount.lastSelectedAccount.accountLegacyId,
            tokens: addTokens,
            email: currentUserAccount.email
          }
        }
        console.log( 'payload body:', { body } );

        // return
        // v2 api
        this.apiService.post(
          'v2',
          'updateMobileDevice',
          body
        )
          .pipe(
            shareReplay( 1 ),
            catchError( err => {
              if ( err ) {
                console.log( 'updateMobileDeviceV2Error:', { err } );
              }
              throw err;
            } )
          )
          .subscribe( updateMobileDeviceResponse => {
            // console.log( { updateMobileDeviceResponse } );
          } ),
          // ERROR user account observable error handling
          catchError( err => {
            if ( err ) {
              console.log( 'currentUserAccountError: SubscriptionError', { err } );
            }
            throw err;
          } )
      } )
  }

  public isOrderInCurrAccount( account: string ): boolean {
    // get the account the notificaiton exist in
    const currAccount = this.user.session.accountId.toString();

    this.notificationList && this.notificationList.value.map( note => {
      if ( note ) {
        // const noteAcct = note && note.data.accountId;

        if ( note && note.data.accountId === account ) {
          //  console.log( noteAcct, ':and:', account, " ::eq:: ", noteAcct === account );
          this.notificationsPerAccount.activeAcct.push( note );

          // console.log( 'this: active:', this.notificationsPerAccount );
        } else {
          // console.log( noteAcct, ':and:', account, "::eq::", noteAcct === account );
          this.notificationsPerAccount.nonActiveAccts.push( note );
          //  console.log( 'this: non-active:', this.notificationsPerAccount );
        }
      }
    } )
    return account === currAccount;
  }

  /* TOKENS Exist in BE */
  async checkDuplicateTokens( atoken: string, currList: string[] ): Promise<any> {
    const findMatch = currList && currList.find( nextItem => {
      // `console.log( 'atoken === nextItem', atoken === nextItem );
      return atoken === nextItem;
    } )
    return atoken === findMatch;
  }

  /* SAVE After changing notification settings if appicable */
  async registerAfterSaveNotificationSettings() {
    // GRANTED check if ios or android has granted notifications
    if ( !this.granted || !this.checkUserOptIn() ) {
      // TODO change to toast message?
      alert( `In order to receive notifications you have to set your phone's notification settings for this app, in your phone's settings.` );
      return;
    };
    // Register.....
    console.log( 'optIn::::', this.checkUserOptIn() );
    console.log( 'optIn::::', this.checkDuplicateTokens( this.fcmToken, this.currListOfTokens ) );
    this.registerToken();
  }

  public async getDeliveryTrackingSettings() {
    const results = await this.apiService.get(
      'v2',
      'getDeliverySetting',
      ''
    )
    this.deliveryNotificationSettings.next( results && results.data.result.dtNotificationSettings );
    // console.log( 'dtSettings:', this.deliveryNotificationSettings.value );
  }

  /* OPTIN EVALUATE settings to see if notifications are set in app */
  public async checkUserOptIn(): Promise<boolean> {
    if ( !this.granted ) return false;

    await this.getDeliveryTrackingSettings();
    console.log( 'this.deliveryNotif', this.deliveryNotificationSettings.value );

    const { rescheduled, delivered, scheduled, ordered, received } = this.deliveryNotificationSettings && this.deliveryNotificationSettings.value;

    /* IMPORTANT */
    if ( rescheduled || delivered || scheduled || ordered || received ) {
      this.userOptedIn.next( true );
      return true;
    }
    /* otherwise */
    console.log( '*** - Customer has opted not to receive push notifictions' );
    this.userOptedIn.next( false );
    return false;
  }

  async isBadToken( tokens: string[] ) {
    tokens && tokens.map( async m => {
      /* Options */
      const options: HttpOptions = {
        url: 'https://fcm.googleapis.com/fcm/send',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'key=AAAArpeg3qc:APA91bHbqjKzanOgzYpbNUKfW7rZF_fzwgc4XZiquXqOV2Jfuog6w_GazpaKrMgums9eH-AxmZ3n3cHRK2r51Uozvr-nNDI4G0Ztq54Ay0GI2OOu4FaOr6qRWSaEFtUNbkTarGUZQVMj',
        }, // TODO remove token from code
        method: 'POST',
        data: {
          to: m,
          dry_run: true, // DRYRUN this flag allows for test without sending real message
          notification: {
            title: 'Test for bad token',
            body: 'Test token'
          }
        }
      };
      /* FCM Request */
      await Http.request( options )
        .then( resp => resp )
        .then( done => {
          if ( Math.floor( done.data.failure ) === 1 ) {
            this.badToken.push( m );
            // console.log( { m } );
            // console.log( { done } );
            console.log( 'done.data.failure', Math.floor( done.data.failure ), 'type', typeof ( done.data.failure ) );
            return done.data.failure ? true : false;
          }
        } );
      return this.badToken;
    } );
    return this.badToken;
  }

  async confirmSwitchAccount( id: string ) {
    console.log( 'confirm: switch: acct:', { id } );


    // TODO if no ask user if he/she would like to switch accounts to view order status change (perform switch account behavior )
    // ok = save cart | switch account | navigate to proplus/orders/detail
    // no = navigate to proplus/orders/detail

    // if same account dont popup
    if ( this.isOrderInCurrAccount( id ) ) {
      this.router.navigate( ['/proplus/orders'] );
      return;
    }

    await this.alert
      .create( {
        header: 'Confirm',
        message: `Are you sure you want to view your order status change in account ${id}? This will save your cart and switch your account.`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              // console.log( 'Confirm Cancel' );
            },
          },
          {
            text: 'Switch',
            handler: async () => {
              await this.changeAccount( id );
              this.cart.resetCartBadge();
              // TODO save cart here
              await this.registerToken();
              this.modal.dismiss();
              /*  let navigationExtras: NavigationExtras = {
                 queryParams: {
                   orderId: accountSelected.orderId,
                   accountId: accountSelected.accountId,
                   accountToken: accountSelected.accountToken // HEMA now
                 }
               }; */
              // this.router.navigate( ['/proplus/orders/detail'], navigationExtras );
              this.router.navigate( ['/proplus/orders'] );
            },
          },
        ],
      } )
      .then( ( alert ) => alert.present() );
  }
  async changeAccount( accountSelected: any ) {
    await this.user.setAccountNotification( accountSelected );
  }

  async sendDryRunNotification( token: string ): Promise<boolean> {
    let isBad = false;

    const options: HttpOptions = {
      url: 'https://fcm.googleapis.com/fcm/send',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'key=AAAArpeg3qc:APA91bHbqjKzanOgzYpbNUKfW7rZF_fzwgc4XZiquXqOV2Jfuog6w_GazpaKrMgums9eH-AxmZ3n3cHRK2r51Uozvr-nNDI4G0Ztq54Ay0GI2OOu4FaOr6qRWSaEFtUNbkTarGUZQVMj',
      }, // TODO remove token from code
      method: 'POST',
      data: {
        to: token,
        dry_run: true, // DRYRUN this flag allows for test without sending real message
        notification: {
          title: 'Test for bad token',
          body: 'Test token'
        }
      }
    };
    /* FCM Request */
    await Http.request( options )
      .then( resp => resp )
      .then( done => {
        if ( Math.floor( done.data.failure ) === 1 ) {
          this.badToken.push( token );
          isBad = true;
          console.log( { token } );
          console.log( { done } );
          console.log( 'done.data.failure', Math.floor( done.data.failure ), 'type', typeof ( done.data.failure ) );
          return true;
        }
      } );

    return isBad;
  }
}
