import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController, NavParams } from '@ionic/angular';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Facet } from '../../classes/facet';
import { FacetGroup } from '../../classes/facet-group';
import { ProductBrowseService } from '../../services/product-search.service';
import { SearchService } from '../../services/search.service';
import { UserService } from '../../services/user.service';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-product-filter',
  templateUrl: './product-filter.component.html',
  styleUrls: ['./product-filter.component.scss'],
})
export class ProductFilterComponent implements OnInit {
  productData: any;
  facetGroups$!: Observable<FacetGroup[] | null>;
  selectedFacets$!: Observable<Facet[] | null>;
  facetsParamsInfo: any;
  filterGroup: any = {};
  categorySelect: any;

  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
    public userService: UserService,
    private search: SearchService,
    public formBuilder : FormBuilder,
    public productBrowseService: ProductBrowseService,
    private analyticsService: AnalyticsService
  ) { 
    this.filterGroup = this.formBuilder.group({
      category: new FormControl(''),
      brand: new FormControl(''),
      color: new FormControl(''),
      thickness: new FormControl(''),
      diameter: new FormControl(''),
      dimension: new FormControl(''),
      length: new FormControl(''),
      width: new FormControl(''),
      packaging: new FormControl(''),
    })
  }

  ngOnInit() {
    
  }
  
  ionViewWillEnter() {
    this.productData = this.navParams.get('productData');
    this.facetsParamsInfo = this.navParams.get('facetsParamsInfo')
    // this.facetGroups$ = this.productBrowseService.facetGroups$;
    // this.selectedFacets$ = this.productBrowseService.selectedFacets$;
    // console.log('this.facetGroups$', this.facetGroups$)
    // console.log('productData from search product page', this.productData)
  }
  applyFilters() {
    const filterSelected = this.filterGroup.value;
    // console.log('filterselect', filterSelected);
    this.modalCtrl.dismiss(filterSelected);
  }

  async getFilterItems(event: any) {
    // console.log("selected value", event);
    // this.modalCtrl.dismiss(event.detail.value);
    this.analyticsService.logEvent(
      'search_by_filter',
      {
        filter_click: this.filterGroup.value,
      }
    );
    this.modalCtrl.dismiss(this.filterGroup.value);
  }
  dismiss(data?: any) {
    // using the injected ModalController this page
    // can "dismiss" itself and pass back data
    this.modalCtrl.dismiss(data);
  }

}
