import { Injectable } from '@angular/core';
import { HttpResponse } from '@capacitor-community/http';

import { ApiService } from '../core/services/api.service';
import { ToastService } from './toast.service';
import { UserService } from './user.service';

import {
  ProductItemRequest,
  ProductDetailsResponse,
  ProductSearchRequest,
  ItemListResponseV2,
  ConfirmationProduct,
  ProductSpecifications,
  ProductResources,
  PricingResponse,
  PricingRequest,
} from '../models/products.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { ConfirmAvailableProductsComponent, ProductConfirmationConfig } from '../components/confirm-available-products/confirm-available-products.component';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private productDesription$ = new BehaviorSubject<string>('');
  private productSpecifications$ = new BehaviorSubject<ProductSpecifications>(
    {}
  );
  private productItemNumber$ = new BehaviorSubject<string>('');
  private productDocuments$ = new BehaviorSubject<ProductResources>({});
	private urlPath$ = new BehaviorSubject<string>('');
  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private toast: ToastService,
		public router: Router,
    public modalController: ModalController,
    public loadingCtrl: LoadingController,
  ) {}

  async getItemDetails(queryOptions: ProductItemRequest) {
    const queryParams = `?productId=${queryOptions.productId}&itemNumber=${queryOptions.itemNumber}&accountId=${this.userService.session.accountId}`;
    const response: HttpResponse = await this.apiService.get(
      'v2',
      'itemDetails',
      queryParams
    );
    // console.log({ response });

    const productDescription =
      response.data.product && response.data.product.longDesc;
    this.productDesription$.next(productDescription);

    const productSpecifications = response.data.specification;
    this.productSpecifications$.next(productSpecifications);

    const productItemNumber =
      response.data.currentSKU && response.data.currentSKU.itemNumber;
    this.productItemNumber$.next(productItemNumber);

    const productDocuments = response.data.resource;
    this.productDocuments$.next(productDocuments);
		
		const urlPath = this.router['routerState'].snapshot.url;
		this.urlPath$.next(urlPath);

    return response.data;
  }
  geturlPath(): Observable<string> {
    return this.urlPath$.asObservable();
  }
  seturlPath(description: string) {
    this.urlPath$.next(description);
  }
  async getProductResouceApi(params: string) {
    const resourceApiResponse: HttpResponse =
      await this.apiService.getResourceApi(params);
    return resourceApiResponse.data;
  }
  getProductDescription(): Observable<string> {
    return this.productDesription$.asObservable();
  }
  setProductDescription(description: string) {
    this.productDesription$.next(description);
  }
  getProductSpecifications(): Observable<ProductSpecifications> {
    return this.productSpecifications$.asObservable();
  }
  setProductSpecifications(specifications: ProductSpecifications) {
    return this.productSpecifications$.next(specifications);
  }
  getProductItemNumber(): Observable<string> {
    return this.productItemNumber$.asObservable();
  }
  setProductItemNumber(itemNumber: string) {
    return this.productItemNumber$.next(itemNumber);
  }
  getProductDocuments(): Observable<ProductResources> {
    return this.productDocuments$.asObservable();
  }
  setproductDocuments(documents: ProductResources) {
    return this.productDocuments$.next(documents);
  }
  async searchProducts(queryOptions: ProductSearchRequest) {
    const queryParams = `
			?facetsFilter=
			&accountId=${queryOptions.accountId}
			&filter=${queryOptions.filter}
			&pageNo=${queryOptions.pageNo}
			&pageSize=${queryOptions.pageSize}
			&showSkuList=${queryOptions.showSkuList}
		`;
    const response: HttpResponse = await this.apiService.get(
      'v2',
      'itemlist',
      queryParams
    );
    if (response.status !== 200) {
      throw new Error('Error in getting searchProducts');
    }
    // console.log('searchProducts: ', response.data);
    return response.data;
  }
  async checkProductsBeforeAdding(
    products: ConfirmationProduct[],
    cartOrTemplate: string,
    whenUserSaysOk: (availableItems: Set<string>) => void
  ) {
    //  Abort if we're not logged in...
    const currentUser = this.userService.userSession.value.sessionInfo;
    if (!currentUser || !currentUser.accountBranch) {
      this.toast.showMessage('Failed to get user info');
      return;
    }

    const unfilteredProducts = products.filter((i) => i.itemNumber !== '0');
    //  Filter out invalid products
    const cleanedProducts = unfilteredProducts.map((i) => i.itemNumber);

    //  If there's nothing to add, let the user know
    if (cleanedProducts.length === 0) {
      this.toast.showMessage('You have no stock products to add.');
      this.loadingCtrl.dismiss();
      return;
    }
    const skuCheckResult = await this.getSKUBranchOrRegionAvailability({
      skuIds: cleanedProducts.join(','),
      regionId: currentUser.accountBranch.branchRegionId,
      marketId: currentUser.accountBranch.market
        ? currentUser.accountBranch.market
        : '',
    });

    //  Only keep the regionally available ones
    let availableSkuArray: string[] = [];
    if (currentUser.accountBranch.market) {
      availableSkuArray = ((skuCheckResult && skuCheckResult.result) || [])
        .filter((s) => s.isSKUAvailableAtRegion.toLowerCase() === 'yes')
        .map((s) => s.sku_id);
    } else {
      availableSkuArray = ((skuCheckResult && skuCheckResult.result) || [])
        .filter(
          (s) => s.isSKUAvailableAtRegion.toLowerCase() === 'yes' && !s.market
        )
        .map((s) => s.sku_id);
    }

    const availableSkuList = new Set<string>(availableSkuArray);
    const unavailableSkuList = new Set<string>(
      cleanedProducts.filter((p) => !availableSkuList.has(p))
    );

    //  Don't show a dialog if everything is available
    if (unavailableSkuList.size === 0) {
      whenUserSaysOk(availableSkuList);
      return;
    }
    const availableLineItems = unfilteredProducts.filter((l) =>
      availableSkuList.has(l.itemNumber)
    );
    const unavailableLineItems = unfilteredProducts.filter(
        (l) => !availableSkuList.has(l.itemNumber)
    );
    const config: ProductConfirmationConfig = {
        unavailableLineItems,
        availableLineItems,
        unavailableSkuList: Array.from(unavailableSkuList),
        cartOrTemplate,
        whenUserSaysOk,
    };
    const modal = await this.modalController.create({
      component: ConfirmAvailableProductsComponent,
      swipeToClose: true,
			breakpoints: [0, 0.5, 0.75, 1],
			initialBreakpoint: 1,
      componentProps: config,
    } as any);
    await modal.present();
    this.loadingCtrl.dismiss()
  
  }
  public async getSKUBranchOrRegionAvailability(req: {
    skuIds: string;
    branchId?: string;
    regionId?: string;
    marketId?: string;
  }) {
    // tslint:disable-next-line: no-any
    const queryParams = `?skuIds=${req.skuIds}&regionId=${req.regionId}&marketId=${req.marketId}`;
    const response: HttpResponse = await this.apiService.get(
      'v2',
      'getSKUBranchOrRegionAvailability',
      queryParams
    );
    return response.data;
  }

}
