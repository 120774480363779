// import { FcmService } from 'src/app/services/fcm.service';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { HttpResponse } from '@capacitor-community/http';

import { SessionInfo } from '../core/services/session-info.service';
import { ApiService } from '../core/services/api.service';

import { LoginRequest } from '../models/login.model';
import { AccountDetails, AccountView } from '../models/account.model';
import { AllPermissions } from '../models/permissions.model';
import { CurrentUser } from '../models/user.model';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastService } from './toast.service';
import { JobResponse } from '../models/job-response.model';
import { LoadingController } from '@ionic/angular';

@Injectable( {
  providedIn: 'root',
} )
export class UserService {
  getDeliveryTrackingSettings() {
    throw new Error( 'Method not implemented.' );
  }
  public isBrowser: boolean;
  public readonly userSession = new BehaviorSubject<SessionInfo>(
    new SessionInfo( { tag: 'notInitialized' } )
  );

  private currentUserAccount = new BehaviorSubject<any>( {} );
  public currentUserAccount$ = this.currentUserAccount.asObservable();

  constructor(
    public storage: Storage,
    public apiService: ApiService,
    public toast: ToastService,
    // public fcm: FcmService,
    private loadingController: LoadingController,

    public router: Router,
    @Inject( PLATFORM_ID ) platformId: string
  ) {
    this.isBrowser = isPlatformBrowser( platformId );
  }

  public get session() {
    return this.userSession.value;
  }
  public get isLoggedIn(): boolean {
    return this.session.isLoggedIn;
  }

  public async getCurrentUserPermission(): Promise<AllPermissions | undefined> {
    const session = this.userSession.value;
    return await this.setSessionPermissions( session );
  }

  public setCurrentUserAccount( list: any ) {
    this.currentUserAccount.next( list );
  }
  public async getApproverList() {
    const response: HttpResponse = await this.apiService.get(
      'v2',
      'approver',
      ''
    );
    return response;
  }
  public async setAccount( accountSelected: AccountDetails ): Promise<any> {
    const accountId = accountSelected.accountLegacyId;
    if ( accountId ) {
      this.apiService
        .post( 'v1', 'switchAccount', { accountId } )
        .subscribe( async ( res ) => {
          // console.log(res);
          if ( res.status === 200 ) {
            await this.getSessionInfo();
            if ( this.router.url !== '/proplus/home' ) {
              this.router
                .navigate( ['/proplus/home'], { replaceUrl: true } )
                .then( () => {
                  // window.location.reload();
                  this.toast.showMessage( 'Account changed successfully', 5000 );
                } );
            } else {
              this.toast.showMessage( 'Account changed successfully', 5000 );
            }
          }
        } );
    }
  }

  public async setAccountNotification( accountSelected: any ): Promise<any> {
    console.log( { accountSelected } );

    const accountId = accountSelected;
    if ( accountId ) {
      this.apiService
        .post( 'v1', 'switchAccount', { accountId } )
        .subscribe( async ( res ) => {
          // console.log(res);
          if ( res.status === 200 ) {
            await this.getSessionInfo();
            this.toast.showMessage( 'Account changed successfully', 5000 );
          }
        } );
    }
  }

  public async getSessionInfo() {
    const user = await this.getUserLoginInfo();
    this.setCurrentUserAccount( user );
    if ( !user ) {
      this.setSession( new SessionInfo( { tag: 'notLoggedIn' } ) );
      return null;
    }

    try {
      // Fetch the user's permissions
      const permissions = await this.fetchPermissions();
      //  Create a new session
      const session = new SessionInfo( {
        tag: 'loggedIn',
        user,
      } ).setPermissions( permissions );
      //  Set the session
      this.userSession.next( session );

      // Get the user's profile and update the session asynchronously
      this.getAdminOrMaster().then( ( adminOrMaster ) => {
        // console.log('USER adminOrMaster: ', adminOrMaster);
        session.setIsAdminOrMaster( adminOrMaster );
        this.userSession.next( session );
      } );
      // Get the user's account list and update the session asynchronously
      this.getAccountList().then( ( accounts ) => {
        // console.log('USER accountList: ', accounts);
        session.setAccountList( accounts );
        this.userSession.next( session );
      } );

      // console.log( 'SESSION INFO: ', this.session );
      return this.session.sessionInfo;
    } catch ( e ) {
      throw e;
    }
  }

  public doLogin( formData: LoginRequest ) {
    return this.apiService.post( 'v1', 'login', formData ).pipe(
      tap( async ( res ) => {
        const { status } = res;
        console.log( { status } );
        if ( status === 401 ) {
          console.log( 'In not Logged In....' );
          this.setNotLoggedIn();
        }
        if ( status === 500 ) {
          this.loadingController.dismiss();
          console.log( 'In not Logged In....' );
          this.setNotLoggedIn();
          this.router.navigate( ['/404'] );
        }
      } )
    );
  }
  public doLogout() {
    return this.apiService.post( 'v1', 'logout', {} ).pipe(
      tap( ( res ) => {
        this.setSession( new SessionInfo( { tag: 'notLoggedIn' } ) );
      } )
    );
  }

  private async getUserDetail( id: string ) {
    try {
      const queryParams = `?id=${id}`;
      const res: HttpResponse = await this.apiService.get(
        'v2',
        'getUserDetail',
        queryParams
      );
      if ( !res || !res.data ) {
        return null;
      }

      return res.data;
    } catch ( error ) {
      return null;
    }
  }

  public async getAccounts() {
    if ( !this.isBrowser ) {
      return null;
    }
    return await this.ensureGetAccounts();
  }

  public async ensureGetAccounts() {
    const profileId = this.session.profileId;
    if ( this.session.tag !== 'loggedIn' ) {
      throw new Error( 'You must be logged in to fetch accounts' );
    }
    if ( !profileId ) {
      throw new Error( 'Cannot fetch accounts' );
    }
    const queryParams = `?profileId=${profileId}`;
    const res: HttpResponse = await this.apiService.get(
      'v1',
      'accounts',
      queryParams
    );
    if ( !res || !res.data ) {
      throw new Error( 'Failed to get accounts' );
    }
    res.data.accounts.sort( ( a, b ) => {
      const aName = ( a.accountName || '' ).replace( /^\d+\s+/, '' );
      const bName = ( b.accountName || '' ).replace( /^\d+\s+/, '' );
      if ( aName === bName ) {
        return 0;
      } else if ( aName > bName ) {
        return 1;
      } else {
        return -1;
      }
    } );
    return res.data;
  }

  private async getUserLoginInfo(): Promise<CurrentUser | null | undefined> {
    // if (this.session.tag !== 'notInitialized') {
    //   return this.session.sessionInfo;
    // }
    const res: HttpResponse = await this.apiService.get(
      'v1',
      'getCurrentUserInfo',
      ''
    );
    return res.data;
  }

  public async getUserJobs(
    alertUser: boolean,
    accountId?: string,
    throwError = true
  ): Promise<JobResponse> {
    const currentId = this.apiService.userSession.value.accountId;
    accountId = accountId || ( currentId ? currentId.toString() : undefined );
    if ( !accountId ) {
      // throw new Error('Failed to fetch user jobs');
    }
    // const { ok, body } = await this.apiService.get<JobResponse>('jobs', {
    //     accountId,
    // });
    // if (!ok || !body) {
    //     throw new Error('Failed to fetch user jobs');
    // }
    let queryParams = `?accountId=${accountId}`;
    const res: HttpResponse = await this.apiService.get(
      'v1',
      'jobs',
      queryParams
    );
    // console.log('user res.data', res.data);
    if (
      res.data.isJobAccountRequired &&
      ( !res.data.jobs || !res.data.jobs.length )
    ) {
      const res: JobResponse = {
        message: '',
        hasJobAccount: false,
        hasJobNumber: false,
        isJobAccountRequired: true,
        jobs: [],
      };
      return res;
    }
    return res.data;
  }
  private setSession( newSession: SessionInfo ) {
    this.userSession.next( newSession );
    //  If the session is being set to a new logged-in state
    if ( newSession.isLoggedIn ) {
      //  Try to update the session's permissions
      // tslint:disable-next-line: no-floating-promises
      this.setSessionPermissions( newSession );
    }
  }
  setDeliveryNotificationSettings( request: any ) {
    return this.apiService.post( 'v2', 'saveDeliverySetting', request );
  }
  async getDeliveryNotificationSettings() {
    const response: HttpResponse = await this.apiService.get(
      'v2',
      'getDeliverySetting',
      ''
    );
    console.log( { response } );

    return response;
  }

  private setNotLoggedIn() {
    this.userSession.next( new SessionInfo( { tag: 'notLoggedIn' } ) );
  }

  private async setSessionPermissions(
    session: SessionInfo
  ): Promise<AllPermissions | undefined> {
    if ( !session.isLoggedIn ) {
      return undefined;
    }
    //  If we have the permissions in our session, no need to fetch them again
    if ( session.userPermissions ) {
      return session.permissions;
    }
    const permissions = await this.fetchPermissions();

    session.setPermissions( permissions );
    this.userSession.next( session );

    this.getAdminOrMaster().then( ( adminOrMaster ) => {
      session.setIsAdminOrMaster( adminOrMaster );
      this.userSession.next( session );
    } );
    this.getAccountList().then( ( accounts ) => {
      session.setAccountList( accounts );
      this.userSession.next( session );
    } );

    return session.permissions;
  }

  private async fetchPermissions() {
    // console.trace('fetchPermissions: ');

    const res: HttpResponse = await this.apiService.get(
      'v2',
      'getCurrentUserPermission',
      ''
    );
    if ( !res.data || !res.data.result ) {
      throw new Error( 'Failed to get user permissions' );
    }
    // console.log('USER SRV getCurrentUserPerm: ', res.data);
    const permissions = res.data.result;

    return permissions;
  }

  private async getAdminOrMaster() {
    let isAdminOrMaster = false;
    if ( this.session && this.session.profileId ) {
      const response = await this.getUserDetail(
        this.session.profileId.toString()
      );
      if ( response && response.result.role.id ) {
        const userDetail = response.result;
        if (
          userDetail.role.id === '200003' ||
          userDetail.role.id === '200001'
        ) {
          isAdminOrMaster = true;
        } else {
          isAdminOrMaster = false;
        }
      }
    }
    return isAdminOrMaster;
  }

  private async getAccountList(): Promise<AccountView[]> {
    const accountResponse = await this.getAccounts();
    const accountList = ( accountResponse ? accountResponse.accounts : [] ).map(
      ( a: AccountView ) => {
        const newName = a.accountName
          ? a.accountName.replace( /\d+\s+\((.+)\)/, '$1' )
          : '';
        const temp: AccountView = { ...a, accountName: newName };
        return temp;
      }
    );
    return accountList;
  }

  public get accountId(): number | null {
    return this.session.accountId;
  }
}
