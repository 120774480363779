import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, UrlTree } from '@angular/router';

import { UserService } from './../../services/user.service';
import { AllPermissions } from '../../models/permissions.model';

@Injectable( {
  providedIn: 'root'
} )
export class AuthGuard implements CanActivate {
  userPerm?: AllPermissions;
  registerDevice: any = null;

  constructor( private userService: UserService, private router: Router ) { }
  async canActivate(
    route: ActivatedRouteSnapshot,
  ): Promise<boolean | UrlTree> {
    try {
      const userInfo = await this.userService.getSessionInfo();
      this.userPerm = this.userService.session.permissions;

      if ( userInfo && this.userService.isLoggedIn ) {
        return true;
      } else {
        await this.router.navigate( ['/login'] );
        return false;
      }
    } catch ( err ) {
      await this.router.navigate( ['/login'] );
      return false;
    }
  }
} 