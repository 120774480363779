import { Component, OnChanges, OnInit } from '@angular/core';
import { ModalController, IonNav, Platform, LoadingController } from '@ionic/angular';
import { ProductsService } from 'src/app/services/products.service';
import { SearchService } from 'src/app/services/search.service';
import { UserService } from 'src/app/services/user.service';
import { getActiveVariations, IAttrOption, toSet, Variations } from 'src/app/models/variations.model';
import { FacetSelector } from '../../pages/template-detail/facet-selector';
import { ProductQuickAddComponent } from '../product-quick-add/product-quick-add.component';
import { Observable } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core'
@Component({
  selector: 'app-add-product-modal',
  templateUrl: './add-product-modal.component.html',
  styleUrls: ['./add-product-modal.component.scss'],
})
export class AddProductModalComponent implements OnInit, OnChanges{
  level = 0;
  nextPage = AddProductModalComponent;
  pageNo: number = 1;
  pageSize: number = 20;
  searchTerm: string = '';
  productData$: Observable<any>;
  searchData: any[];
  facetSelectors!: { name: string; value: string }[];
  activeVariations: Record<string, IAttrOption[]>;
  isLoad: boolean;
  constructor(
    private modalController: ModalController, 
    private nav: IonNav, 
    private user: UserService, 
    private search: SearchService, 
    public loadingCtrl: LoadingController,
    private changeRef: ChangeDetectorRef,
    private readonly productsService: ProductsService,
    public modal: ModalController
  ) { }

  async ngOnInit() {
    const queryOptions = {
      hoverSearch: false,
      accountId: this.user.session.accountId, 
      showHoverAttrs: false,
      filter:  this.searchTerm,
      pageNo: this.pageNo, 
      pageSize:this.pageSize,
      showSkuList: true,
    };
    const queryParams = `?${Object.keys(queryOptions).map(key => key + '=' + queryOptions[key]).join('&')}`;

    const response = await this.search.getItemList(queryParams);
    // console.log(response, 'modal response');
    
    this.searchData = response.items;
  }
  ngOnChanges(){
    // console.log('ngOnChanges');
  }
  // ionViewDidEnter() {
  //   console.log('ionViewDidEnter');
    
  // }
  async lookProduct(event: any){
    // console.log('searchText', event.target.value);
    this.searchData = [];
    if(event.target.value === ''){
      return;
    }
    const queryOptions = {
      accountId: this.user.session.accountId, 
      filter: event.target &&  event.target.value ? event.target.value : '',
      hoverSearch: false,
      showHoverAttrs: false,
      pageNo: this.pageNo, 
      pageSize:this.pageSize,
      showSkuList: true,
    };
    const queryParams = `?${Object.keys(queryOptions).map(key => key + '=' + queryOptions[key]).join('&')}`;


    const response = await this.search.getItemList(queryParams);
    this.searchData = response.result && response.result.items;
    // console.log('typeAhead response', this.searchData);
    
  }
  ionViewDidEnter() {
    // console.log('view did enter');
    this.changeRef.detectChanges();
  }

  async getItemDetails(item: any, ctaClick?: string){
    this.isLoad = true;
    this.productData$ = item;
    // console.log(item, 'getItemDetails item');
    let setDefaultFacet = true;
    // console.log(item, 'modal item');
    this.modalController.dismiss()
    const modal = await this.modalController.create({
			component: ProductQuickAddComponent,
			swipeToClose: true,
			breakpoints: [0, 0.5, 0.75, 1],
			initialBreakpoint: 0.75,
      componentProps:{
        'ctaClick': 'selectProduct',
        'productItem': item
      }
		} as any);
		return await modal.present();
    const queryParams = {
			productId: item.productId,
			itemNumber: item.itemNumber,
			accountId: item.accountId
		}
		// console.log('itemNumber:', item.itemNumber);
		const loading = await this.loadingCtrl.create();
		await loading.present();
		const res = await this.productsService.getItemDetails(queryParams);
    this.productData$ = res;
    console.log(this.productData$, 'from productdata');
    const variationSet = toSet(res.variations);
    const filteredVariation = variationSet.filter(
      (v) => v.sku === res.product.itemNumber
    );
    const selectedFilters = getSelectedFiltersForSku(
      res.product.itemNumber,
      res.variations
    );
    const { facetOptions } = getActiveVariations(
      selectedFilters,
      res.variations
    );
    this.activeVariations = facetOptions;
    this.facetSelectors = Object.keys(res.variations)
      .filter((name) => (this.activeVariations[name] || []).length >= 1)
      .map((name) => {
        if (!setDefaultFacet) {
          return new FacetSelector(null, name, '');
        }
        const variationsForName = filteredVariation.filter(
          (fv) => fv.attr === name
        );
        const currVariation = res.currentVariations
          ? res.currentVariations[name]
          : null;
        const newValue =
          variationsForName.length > 0
            ? variationsForName.length === 1
              ? variationsForName[0].attrValue
              : currVariation
              ? currVariation
              : variationsForName[0].attrValue
            : '';
        return new FacetSelector(null, name, newValue);
      });
      this.isLoad = false;
      this.changeRef.markForCheck();
      this.changeRef.detectChanges();
    loading.dismiss();
    
  }
  goForward() {
    this.nav.push(this.nextPage, { level: this.level + 1 });
  }

  goRoot() {
    this.nav.popToRoot();
  }

  close() {
    this.modalController.dismiss();
  }

}

function getSelectedFiltersForSku(sku: string, variations: Variations) {
  const selection = new Map<string, string>();
  Object.entries(variations).forEach((v) => {
      const [facetName, attributes] = v;
      for (const [facetValue, skus] of Object.entries(attributes)) {
          const hasSku = skus.some((s) => s === sku);
          if (hasSku) {
              selection.set(facetName, facetValue);
          }
      }
  });
  return selection;
}