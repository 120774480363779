import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import {
  AlertController,
  IonRouterOutlet,
  LoadingController,
  ModalController,
  ToastController,
  Config,
  ActionSheetController,
} from '@ionic/angular';
import { NavigationExtras, Router } from '@angular/router';

import { ChatService } from '../../services/chat.service';
import { UserService } from '../../services/user.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit {
  @Input() name: string;
  @Input() url: string;
  @Input() subMenus: any[];
  @Input() contactUs: any[];

  activeLabel = '';
  @Input() onLogout: boolean;
  @Input() onOpenChat: boolean;
  isListItemOpened: boolean = false;
  isChildMenuItems: boolean = false;
  isChildSubMenuItems: boolean = false;
  loading: any;
  account: any;
  constructor(
    public loadingCtrl: LoadingController,
    public user: UserService,
    public router: Router,
    private readonly chatService: ChatService,
    private analyticsService: AnalyticsService,
    public actionSheetCtrl: ActionSheetController
  ) {}
  ngOnInit() {}
  openChat() {
    this.chatService.expandChat(true);
  }
  toggleAccordion(): void {
    this.isListItemOpened =
      (this.subMenus && !this.isListItemOpened) ||
      (this.contactUs && !this.isListItemOpened);
  }
  toggleCategoryAccordion(): void {
    this.isChildMenuItems =
      (this.subMenus && !this.isChildMenuItems) ||
      (this.contactUs && !this.isListItemOpened);
  }
  toggleSubCategoryAccordion(): void {
    this.isChildSubMenuItems =
      (this.subMenus && !this.isChildSubMenuItems) ||
      (this.contactUs && !this.isListItemOpened);
  }
  trackMenus(name) {
    this.analyticsService.logEvent('menu_click', {
      menu_option: name,
    });
  }
  async logout() {
    this.loading = await this.loadingCtrl.create();
    await this.loading.present();
    this.user.doLogout().subscribe((res: any) => {
      if (!res.data && !res.data.messageInfo) {
        return null;
      }
      this.account = undefined;
      this.loading.dismiss();
      this.router.navigate(['login'], { replaceUrl: true }).then(() => {
        window.location.reload();
      });
    });
  }

  navigateToSearch(item) {
    this.analyticsService.logEvent('menu_click', {
      menu_option_dropdown: item.name,
    });
    let navigationExtras: NavigationExtras = {
      queryParams: {
        productLink: item._links.site.href,
        categoryName: item.name,
      },
    };
    this.router.navigate(['/proplus/products'], navigationExtras);
  }

  async openContact(result: any) {
    const mode = 'ios'; // this.config.get('mode');
    // console.log(result, 'result');
    
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Contact: Beacon Support',
      buttons: [
        {
          text: `Call  ${this.formatPhoneNumber(result.phone)}`,
          icon: mode !== 'ios' ? 'call' : null,
          cssClass: 'open-contact',
          handler: () => {
            window.open('tel:' + this.formatPhoneNumber(result.phone));
          },
        },
        {
          cssClass: 'open-contact',
          text: 'Cancel',
          role: 'cancel',
        },
      ],
    });

    await actionSheet.present();
  }

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  openEmail(result: any) {
    //redirect to email client
    // console.log('here');

    window.open('mailto:' + result.email);
  }

  // mailto(email) {
  //   platform.ready().then(() => {
  //       window.open('mailto:'+email);
  //   });
  // }
}

// interface subMenuItem{
//   url: string,
//   name: string,
//   title?: string,
//   childMenuItems?:[]
// }
