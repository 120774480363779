import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { SearchService } from 'src/app/services/search.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent implements OnInit {
  @Output("getItemList") getItemList: EventEmitter<any> = new EventEmitter();
  
  accountId: any;
  pageNo = 1;
  pageSize = 24;
  facetsFilter: '';
  showSkuList: boolean = true;
  itemList: [];
  searchTerm: string = '';
  filter: any;
  loading: HTMLIonLoadingElement;
  productData: any;
  searchData!: any;
  showAutoComplete: boolean = true;
  constructor(private search: SearchService, private userService: UserService, public router: Router) { }

  ngOnInit() {
    this.searchData = [];
  }

  async searchProduct(event: any){
    // console.log('event', event)
    
    this.itemList = [];
    this.pageNo = 1
    this.searchTerm = encodeURI(event.target.value);
    this.getItemList.emit(event); // emit this as output
    let navigationExtras: NavigationExtras = {
      queryParams: {
        searchValue: encodeURI(event.target.value),
      }
    };
    this.router.navigate(['/proplus/products'], navigationExtras);
    
  }
  
  async lookProduct(event: any){
    // console.log('searchText', event.target.value);
    this.showAutoComplete = true;
    this.searchData = [];
    if(event.target.value === ''){
      return;
    }
    const queryOptions = {
      accountId: this.userService.session.accountId, 
      filter: event.target &&  event.target.value ? encodeURI(event.target.value) : '',
    };
    const queryParams = `?${Object.keys(queryOptions).map(key => key + '=' + queryOptions[key]).join('&')}`;


    const response = await this.search.getTypeAhead(queryParams);
    this.searchData = response.result && response.result.items;
    // console.log('typeAhead response', this.searchData);
    
  }
  addFocus(){
    console.log('ionfocus');
    
  }
  removeFocus(){
    console.log('remove focus');
    setTimeout(() => {
      this.showAutoComplete = false;
    }, 500);
    
    // this.searchData = []
  }

  viewItemDetail(item: any) {
    let productId = this.getProductId(item.searchTypeAheadLink);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        productId: `C-${productId}`,
        accountId: this.userService.session.accountId.toString(),
      }
    };
    this.router.navigate(['/proplus/product-detail'], navigationExtras);
  }
  getProductId(str: string){
    let parts = str.split(/[/\/\?&]/g); // split the url string with these characters

    // find the piece with the key `C` since all product id start with a C-
    let filteredParts = parts.filter(function (part) {
      return part.split('-')[0] === 'C';
    });

    // from the filtered array, get the first [0]
    // split the value and key, and grab the value [1]
    let productId = filteredParts[0].split('-')[1];

    return productId; // C-number
  }
}
