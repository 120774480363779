import { Injectable } from '@angular/core';
import { HttpResponse } from '@capacitor-community/http';

import { SessionInfo } from '../core/services/session-info.service';
import { ApiService } from '../core/services/api.service';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { ApiResponse } from '../models/api.model';
import { CartItems } from '../models/cart.model';
import { tap } from 'rxjs/operators';
import { AddItemRequest } from '../components/suggested-items/suggested-items.component';
import {
  ShippingRequest,
  UploadRelatedDocuments,
} from '../pages/cart-review/cart-review.component';

export type SuggestiveSellingResponse = ApiResponse<SuggestiveSellingItem[]>;
export interface SuggestiveSellingItem {
  productId: string;
  productName: string;
  productNameNotTruncate: string;
  pdpUrl: string;
  defaultItem: {
    itemNumber: string;
    unitOfMeasure: string;
    price: 0;
    vendorColorId: string;
    itemImage: string;
    itemOnErrorImage: string;
  };
}
@Injectable( {
  providedIn: 'root',
} )
export class CartService {
  url: string;
  private readonly suggestionsMaps = new Map<
    string,
    Observable<SuggestiveSellingResponse>
  >();
  private cartSizeSubject: Subject<number>;

  private _cartSize: Observable<number>;
  private cartCount = new BehaviorSubject<any>( {} );
  public cartCount$ = this.cartCount.asObservable();
  private _cartItem: any = new BehaviorSubject<any>( [] );
  public cartItem$ = this._cartItem.asObservable();

  constructor( public apiService: ApiService ) {
    this.cartSizeSubject = new Subject();
    this._cartSize = this.cartSizeSubject.asObservable();
  }

  get cartSize() {
    this.getCartItems();
    return this._cartSize;
  }

  async downloadOrderRelatedDocument(
    orderId: string,
    documentName: string
  ): Promise<Blob> {
    const response: any = await this.apiService.getApiDataUrlBlob(
      'v2',
      'downloadOrderDocument',
      {
        orderId: orderId,
        documentName: documentName,
      }
    );
    return response.data.result;
  }
  async getSubmitOrderResult( orderId: string, savedOrderId: string ) {
    const result = await this.apiService.post( 'v2', 'getSubmitOrderResult', {
      orderId,
      savedOrderId,
    } );
    return result;
  }

  async getCartItems() {
    const response: HttpResponse = await this.apiService.get(
      'v2',
      'cartItems',
      ''
    );
    this.cartSizeSubject.next(
      ( response.data.result && response.data.result.cartLineItems ) || 0
    );
    this._cartItem.next( response.data.result );
    return response.data.result;
  }

  async deleteOrdeRelatedDocument( req: any ) {
    const response = await this.apiService.post(
      'v2',
      'deleteOrderRelatedDocuments',
      req
    );
    return response;
  }

  public async uploadOrderRelatedDocuments( req: UploadRelatedDocuments ) {
    const formData = new FormData();
    formData.append( 'orderId', req.orderId );
    formData.append(
      'relatedDocuments',
      req.relatedDocuments.blob,
      req.relatedDocuments.filename
    );
    const response = await this.apiService.post(
      'v2',
      'uploadOrderRelatedDocuments',
      formData
    );
    return response;
  }

  async updateCurrentOrderJobNumber( jobNumber: string ) {
    const response = this.apiService.post(
      'v2',
      'updateCurrentOrderJobNumber',
      jobNumber
    );
    return response;
  }

  /* TODO need error handling here */
  async submitOrder( request: any ) {
    const response = this.apiService.post( 'v2', 'submitCurrentOrder', request );
    return response;
  }

  async getJobAccounts( accountId: any ) {
    const queryParams = `?accountId=${accountId}`;
    const response: HttpResponse = await this.apiService.get(
      'v1',
      'jobs',
      queryParams
    );
    return response.data;
  }

  addItemsToCartFromOrder( request: CartItems ) {
    return this.apiService.post( 'v2', 'addMultipleItemsToOrder', request ).pipe(
      tap( ( res ) => {
        // console.log(res, 'add cart');
        if ( res.data.success ) {
          this.getCartItems();
        }
        // this.cartSizeSubject.next(res.data.result.length);
        return res.data.result;
      } )
    );
  }

  resetCartBadge() {
    this.cartSizeSubject.next( 0 );
  }

  async getSuggestiveSelling2( req: any ) {
    const response: HttpResponse = await this.apiService.get(
      'v2',
      'suggestiveSelling',
      `?account=${req.account}&itemNumber=${req.itemNumber}&pageSize=${req.pageSize}&pageNo=${req.pageNo}`
    );
    return response.data.result;
  }

  async getOrderSummary() {
    const response: HttpResponse = await this.apiService.get(
      'v2',
      'orderSummary',
      ''
    );
    return response.data.result;
  }

  public getSuggestiveSelling( req: any ): Observable<SuggestiveSellingResponse> {
    const itemNumbers = req.itemNumber.join( ',' );

    const key = `${req.account}-${itemNumbers}-${req.pageSize}-${req.pageSize}`;
    const result = this.suggestionsMaps.get( key );
    if ( !!result ) {
      return result;
    }
    const cachedObservable = new ReplaySubject<SuggestiveSellingResponse>( 1 );
    this.suggestionsMaps.set( key, cachedObservable );

    const request: any = {
      account: req.account,
      itemNumber: itemNumbers,
      pageSize: req.pageSize.toString(),
      pageNo: req.pageNo.toString(),
    };

    this.apiService
      .getApiObservable<SuggestiveSellingResponse>(
        'v2',
        'suggestiveSelling',
        request
      )
      .subscribe(
        ( r ) => {
          cachedObservable.next( r );
        },
        ( e ) => {
          this.suggestionsMaps.delete( key );
          cachedObservable.error( e );
        }
      );

    return cachedObservable;
  }
  async addCartItems( request: AddItemRequest ) {
    const response = this.apiService.post(
      'v2',
      'addMultipleItemsToOrder',
      request
    );
    return response;
  }
  async removeItemFromCart( request: any ) {
    const response = this.apiService.post( 'v2', 'removeItemFromCart', request );
    return response;
  }
  updateCart( items: any ) {
    const response = this.apiService.post( 'v2', 'updateCart', { items } );
    return response;
  }

  addUpdateShippingInfo( req: ShippingRequest ) {
    const response = this.apiService.post( 'v2', 'addOrderShippingInfo', req );
    return response;
  }

  saveOrder( savedOrderName: any ) {
    const response = this.apiService.post( 'v2', 'saveOrder', savedOrderName );
    return response;
  }

  clearCart() {
    const response = this.apiService.post( 'v2', 'clearCart', {} );
    return response;
  }
}
