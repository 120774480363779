import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { Device } from '@capacitor/device';

@Injectable( {
  providedIn: 'root'
} )
export class AnalyticsService {
  analyticsEnabled = true;

  constructor( private router: Router ) {
    this.initFb();
    this.router.events.pipe(
      filter( ( e: RouterEvent ) => e instanceof NavigationEnd ),
    ).subscribe( ( e: RouterEvent ) => {
      // console.log('route changed: ', {'event':e, 'url':e.url});
      this.setScreenName( e.url, '' )
    } );
  }

  async initFb() {
    if ( ( await Device.getInfo() ).platform == 'web' ) {
      FirebaseAnalytics.initializeFirebase( environment.firebaseConfig );
    }
  }

  setUserId( id: string ) {
    // Use Firebase Auth uid
    FirebaseAnalytics.setUserId( {
      userId: id,
    } );
  }

  setUserProperty( key: string, value: any ) {
    // debugger
    FirebaseAnalytics.setUserProperty( {
      name: key,
      value: value,
    } );
  }

  async getAppInstanceId() {
    const response = await FirebaseAnalytics.getAppInstanceId();

    const { instanceId } = response;
    console.log( 'Instance ID: ', instanceId );
  };

  logEvent( name: string, properties: object ) {
    // console.log( 'logevent options', { name, properties } );
    FirebaseAnalytics.logEvent( {
      name: name,
      params: properties
    } );
  }

  setScreenName( screenName, nameOverride ) {
    FirebaseAnalytics.setScreenName( {
      screenName: screenName,
      nameOverride: nameOverride
    } );
  }

  toggleAnalytics() {
    this.analyticsEnabled = !this.analyticsEnabled;
    FirebaseAnalytics.setCollectionEnabled( {
      enabled: this.analyticsEnabled,
    } );
  }
}