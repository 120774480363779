import { Injectable } from '@angular/core';
import { HttpResponse, Http } from '@capacitor-community/http';
import { environment } from 'src/environments/environment';
import { ApiService } from '../core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private api: ApiService) {}

  async getItemList(queryParams: any){
    // const queryParams = `?facetsFilter=${queryOptions.facetsFilter},recentlyPurchase:${queryOptions.recentlyPurchase}&pageNo=${queryOptions.pageNo}&filter=${queryOptions.filter}&accountId=${queryOptions.accountId}&cateFilter=${queryOptions.cateFilter}&pageSize=${queryOptions.pageSize}&showSkuList=${queryOptions.showSkuList}`;
    const response: HttpResponse = await this.api.get('v2', 'itemlist', queryParams);
    return response.data;
  }

  async getTypeAhead(queryParams: any){
    const response: HttpResponse = await this.api.get('v2', 'typeAhead', queryParams);
    return response.data;
  }

  async getMenuList(searchTerm: any){
    const resourceApiResponse: HttpResponse = await this.getResourceApi(searchTerm);
    return resourceApiResponse.data;
  }
  getResourceApi(query: string){
    return Http.get({
      url: `${environment.resourseApi}/resourceapi${query}`,
      webFetchExtra: {
        credentials: 'include',
      },
    });
  }
}
